import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import UserService from "../services/user-service/user-data-service";
import Avatar from 'react-avatar';
import { FaEnvelope, FaPhone } from "react-icons/fa"
import { BsArrowDownRightSquare, BsPencil, BsTrash } from "react-icons/bs";
import Modal from 'react-bootstrap/Modal';
import ViewApplication from "./View-Application";
import ApplicationService from "../services/application-service/application-data-service";


const Profile = (loggedInUser) => {

  const navigate = useNavigate();

  const [isLoadEditProfile, setLoadEditProfile] = useState(false);
  const [isLoadEditPassword, setLoadEditPassword] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [message, setMessage] = useState(null);
  const [notification, setNotification] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const isApplication = useState({ id: "", appName: "", envName: "", description: "", endpoint: "",
    storageStatus: "", upTimeStatus: "",  memoryStatus: "", queryDate: "", notes: "" });
  const [data, setData] = useState([]);
  const [activeApplication, setActiveApplication] = useState(isApplication);
  const [isLoadApplication, setLoadApplication] = useState(false);


  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);


  useEffect(() => {
    window.scrollTo(0, 0);

    const userData = loggedInUser.loggedInUser;
    const appData = loggedInUser.data;

    setCurrentUser(userData);
    setData(appData);


    /* ApplicationService.allApplications().then(
      (response) => {
        const data = response.data.reverse();
        const postData = data.map(item => ({
          id: item._id, appName: item.appName, envName: item.envName, description: item.description,
          endpoint: item.endpoint, storageStatus: item.storageStatus, upTimeStatus: item.upTimeStatus, 
          memoryStatus: item.memoryStatus, createdBy: item.createdBy, notes: item.notes, queryDate: item.queryDate
        }))
        setData(postData);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setMessage(message)
      }
    ); */


    setActiveApplication(activeApplication);


  }, [setCurrentUser, activeApplication, loggedInUser, setData]);


  const setViewActiveApplication = (application) => {
    setActiveApplication(application);
    setLoadApplication(true);
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentUser((item) => ({
      ...item,
      [name]: value
    }));
    setNotification("");
  };


  const editMyProfile = async (e) => {
    e.preventDefault();

    UserService.updateMyProfile(currentUser.firstName, currentUser.lastName, currentUser.username,
      currentUser.phoneNumber).then(
        () => {
          setLoading(true);
          setNotification("Update Successfull!");
          setTimeout(() => {
            setLoading(false);
            setLoadEditProfile(false);
            UserService.viewMyProfile();
            window.location.reload();
          }, 3300);
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

            setLoading(true);
            setMessage(message);
            setTimeout(() => {
            setLoading(false);}, 4300);
        }
      );
  };

  const generatePasswordResetOTP_Code = () => {
    UserService.generatePasswordResetOTP_Code().then(
      (response) => {
        setCurrentUser(response.data);
        setLoading(true);
        setLoadEditPassword(true);
        setNotification("OTP successfully sent to your email for password change!");
        setTimeout(() => {
          setLoading(false);
        }, 4300);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

          setLoading(true);
          setMessage(message);
          setTimeout(() => {
          setLoading(false);}, 4300);
      }
    );
  }

  const editMyPassword = async (e) => {
    e.preventDefault();

    UserService.updateMyPassword(currentUser.OTP_Code, currentUser.password).then(
      () => {
        setLoading(true);
        setNotification("Password Updated Successfully!");
        setTimeout(() => {
          setLoading(false);
          setLoadEditPassword(false);
          UserService.viewMyProfile();
          window.location.reload();
        }, 3300);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

          setLoading(true);
          setMessage(message);
          setTimeout(() => {
          setLoading(false);}, 4300);
      }
    );
  };



  const deleteConfirm = () => {
    if (window.confirm("You will loose all your data!Are you sure you want to delete? ")) {
      deleteMyProfile();
    }
  };
  const deleteMyProfile = async () => {
    try {
      UserService.deleteMyProfile()
        .then(() => {
          setLoading(true);
          setNotification("User Deleted Successfully!");
          setTimeout(() => {
            setLoading(false);
            setCurrentUser([]);
            localStorage.removeItem("user");
            navigate("/login");
            window.location.reload();
          }, 3300);
        })
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

        setLoading(true);
        setMessage(message);
        setTimeout(() => {
        setLoading(false);}, 4300);
    }
  }


  return (
    <div className="App">
      {currentUser ? (<div>

      <link rel="stylesheet" href={require("../assets/css/style.css")}></link>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.min.css"></link>
      <link rel="stylesheet" href={require("../assets/css/bootstrap.min.css")}></link>
      <link rel="stylesheet" href={require("../assets/css/bootstrap-reboot.min.css")}></link>

        <section className="container-fluid">
          <section className="row">
            <aside className="sidebar sidebar-nav border border-right col-md-3 col-lg-2">
              <div className="offcanvas-md offcanvas-end bg-body-tertiary" tabIndex="-1" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
                <div className="offcanvas-header">
                  <h5 className="offcanvas-title" id="sidebarMenuLabel">Company name</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto">
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/">
                        <i className="bi-house-fill"></i>
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="/applications">
                        <i className="bi bi-grid"></i><span>Applications</span><i className="bi bi-chevron-down ms-auto"></i>
                      </a>
                      {data && data.length >= 1 ? (
                        data.map((item, key) => 
                          <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                            <Link to={`../view-application/${item.id}`}>
                              <li className="nav-item" key={key} onClick={() => setViewActiveApplication(item, item.id)}>
                                {/* <i className="bi bi-circle"></i> */}
                                {(item.appName === "Ibima" || item.appName === "Bima" || item.appName === "Nanatec" || 
                                    item.appName === "Nexus" || item.appName === "Unitrust") ? 
                                    
                                  <img src={require(`../images/${item.appName}-logo.png`)} 
                                  alt="" style={{ width: "20px", height: "20px", borderRadius: "50%" }}/>
                                  
                                  : 
                                  <Avatar name={item.appName + " " + item.appName} size="20" round={true} />
                                }
                                <span> {item.appName}</span>
                              </li>
                            </Link>
                          </ul>)
                      ) : (
                        <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                          <li className="nav-item">
                            <span>No Apps Found!</span>
                          </li>
                        </ul>
                      )}
                    </li>
                    <li className="nav-item">
                      <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/users">
                        <i className="bi-people"></i>
                        Users
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </aside>

            <main className="dashboard col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="pagetitle">
                {/* <h1>Dashboard</h1> */}
                <nav className="breadcrumbs">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a className="breadcrumb-menu-a" href="/">Home</a></li>
                    <li className="breadcrumb-item"><a className="breadcrumb-menu-a" href="/applications">Applications</a></li>
                    <li className="breadcrumb-item"><a className="breadcrumb-menu-a" href="/users">Users</a></li>
                    <li className="breadcrumb-item"><span style={{ backgroundColor: "#555", color: "white", borderRadius: "5px", padding: "5px" }}>My Profile</span></li>
                  </ol>
                </nav>
              </div>


          {/* </section>
        </section> */}

        <div className="col-lg">
          <div className="row">
            <div className="profile-card-full">
              <div className="profile-card-full-left-card">
              <div className="profile-card-full-header-stat">
                <h4>Profile</h4>
              </div>
              <div className="profile-picture-container">
                <Avatar name={currentUser.firstName + " " + currentUser.lastName} />
              </div>
              <div className="small" style={{ marginTop: "10px", textAlign: "center" }}>
                <div className="config-list-button-view-container"> Edit <br />
                  <button className="config-list-button-edit"
                    onClick={() => { setLoadEditProfile(true); handleShowModal() }}
                  ><BsPencil style={{ fontSize: "18px" }} /> </button>
                </div>
                <div className="config-list-button-warning-container"> Change Password <br />
                  <button className="config-list-button-warning" 
                    onClick={() => { generatePasswordResetOTP_Code(); handleShowModal() }}
                  ><BsArrowDownRightSquare style={{ fontSize: "18px" }} /> </button>
                </div>
                <div className="config-list-button-delete-container"> Delete <br />
                  <button className="config-list-button-delete"
                  onClick={deleteConfirm}
                  ><BsTrash style={{ fontSize: "18px" }} /> </button>
                </div>
              </div>
              </div>
              <div className="profile-card-full-right-card">
              <div className="profile-card-full-header-stat" style={{ textAlign: "left" }}>
                <h4>{currentUser.firstName + " " + currentUser.lastName}</h4>
                <div className="list-line-separator"></div>
              </div>
              <div className="small" style={{ textAlign: "left" }}>
              {/* <h6>Name: 
                <span style={{ color: "#3B71CA" }}>{currentUser.firstName + " " + currentUser.lastName}</span></h6> */}
              <p className="profile-title"><strong style={{ color: "#555" }}>Role: </strong> {currentUser.role}</p>
              {currentUser.status === "Active" && <p><strong style={{ color: "#555" }}>Status: </strong>
                <span className="badge rounded-pill bg-success">{currentUser.status}</span>
              </p>}
              {currentUser.status === "Pending" && <p><strong style={{ color: "#555" }}>Status: </strong>
                <span className="badge rounded-pill bg-warning">{currentUser.status}</span>
              </p>}
              <span className="small"><FaPhone /> <strong style={{ color: "#555" }}> Phone: </strong>  +(254) {currentUser.phoneNumber} <br /> <br />
                <span><FaEnvelope /> <strong style={{ color: "#555" }}> Email: </strong>  <span style={{ color: "#3B71CA" }}> {currentUser.email} </span><br /></span>
              </span>
              </div>
              </div>
            </div>


        <div>

        <Modal
        show={showModal}
        onHide={() => { setLoadEditProfile(false); setLoadEditPassword(false); setNotification(null); setMessage(null); handleCloseModal() }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          
          <div className="modal-header-sign-up">
            <h5>Update My Profile</h5>
            <p>Fill in the details below</p>
            {isLoading && message && (<div className="error"> {message} </div>)}
            {isLoading && notification && (<div className="success"> {notification} </div>)}
          </div>

        </Modal.Header>
        <Modal.Body>

        {currentUser && isLoadEditProfile && <div className="modal-body-sign-up">
                    <div className="col-md-12">
                      <form className="insertForm"
                        onSubmit={editMyProfile} >
                        <div className="modal-sign-up-column">
                          <label htmlFor="firstName">First Name*</label>
                          <input
                            type="text"
                            name="firstName"
                            className="form-control"
                            value={currentUser.firstName}
                            onChange={handleChange}
                            autoComplete="off"
                            required
                          />
                        </div>
                        <div className="modal-sign-up-column">
                          <label htmlFor="lastName">Last Name*</label>
                          <input
                            type="text"
                            name="lastName"
                            className="form-control"
                            value={currentUser.lastName}
                            onChange={handleChange}
                            autoComplete="off"
                            required
                          />
                        </div>
                        <div className="modal-sign-up-column">
                          <label htmlFor="phoneNumber">Phone Number*</label>
                          <input
                            type="text"
                            name="phoneNumber"
                            className="form-control"
                            value={currentUser.phoneNumber}
                            onChange={handleChange}
                            autoComplete="off"
                            required
                          />
                        </div>
                        <div className="modal-sign-up-column">
                          <label htmlFor="username">Username (optional)</label>
                          <input
                            type="text"
                            name="username"
                            className="form-control"
                            value={currentUser.username}
                            onChange={handleChange}
                            autoComplete="off"
                          />
                        </div>
                        <div className="register-button-area">
                          <button className="register-button" type="submit">
                            Update Profile
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>}

                  {currentUser && isLoadEditPassword && <div className="modal-body-sign-up">
                    <div className="col-md-12">
                      <form className="insertForm"
                        onSubmit={editMyPassword} >
                        <div className="modal-sign-up-column">
                          <label htmlFor="password">New Password*</label>
                          <input
                            type="password"
                            name="password"
                            className="form-control"
                            value={currentUser.password}
                            onChange={handleChange}
                            autoComplete="off"
                            required
                          />
                        </div>
                        <div className="modal-sign-up-column">
                          <label htmlFor="OTP_Code">OTP Code*</label>
                          <input
                            type="text"
                            name="OTP_Code"
                            className="form-control"
                            value={currentUser.OTP_Code}
                            onChange={handleChange}
                            autoComplete="off"
                            required
                          />
                        </div>
                        <div style={{ marginTop: "10px", marginLeft: "20px" }}>
                          Did not receive OTP-Code? <button style={{ paddingTop: "10px", marginTop: "10px", border: "0px", color: "blue", textDecorationLine: "underline" }}
                            onClick={generatePasswordResetOTP_Code}
                          > Click Here</button>
                        </div>
                        <div className="register-button-area">
                          <button className="register-button" type="submit">
                            Update My Password
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>}

        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>

        
      </Modal>

        </div>

{/* 

            <div>
              <CreateModal showCreate={showCreate} handleClose={() => {
                handleCloseCreateModal();
                setLoadEditProfile(false); setLoadEditPassword(false); setNotification("")
              }}>
                {currentUser && isLoadEditProfile && <div>
                  <div className="modal-header-sign-up" align="center">
                    <h5>Edit My Profile</h5>
                    <p>Fill in the details below</p>
                  </div>
                  
                </div>}
                {currentUser && isLoadEditPassword && <div>
                  <div className="modal-header-sign-up" align="center">
                    <h5>Update My Password</h5>
                    <p>Fill in the details below</p>
                    {notification && (<div className="success"> {notification} </div>)}
                  </div>
                  
                </div>}
              </CreateModal>
            </div> */}
            {message && (<div /* className="error" */>{message}</div>)}
          </div>
        </div>
        <div>
            {isLoadApplication &&
              <ViewApplication list={activeApplication} />
            }
          </div>
          </main>
          </section>
          </section>
      </div>
      ) : (
        null
      )}
    </div>
  );
};

export default Profile;