import React, { useEffect, useState } from "react";
import '../App.css';
import { Link } from "react-router-dom";
import ViewApplication from "./View-Application";
import ApplicationService from "../services/application-service/application-data-service";
import Avatar from "react-avatar";
import UpdateApp from "./UpdateApp";
import Login from "./Login";
import "bootstrap/dist/js/bootstrap.bundle.js"
import { BsEye, BsPencil, BsTrash, BsSpeedometer } from "react-icons/bs";
import Chart from "chart.js/auto";
import { Line, Doughnut, Pie } from 'react-chartjs-2';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Modal from 'react-bootstrap/Modal';

const statusEnum = [
  { value: "good", label: "good" },
  { value: "warning", label: "warning" },
  { value: "bad", label: "bad" }
]

const Applications = (loggedInUser) => {


  const [runTimeChartData, setRunTimeChartData] = useState();
  const [memoryChartData, setMemoryChartData] = useState();
  const [storageChartData, setStorageChartData] = useState();
  const [apiChartData, setApiChartData] = useState();
  const [currentUser, setCurrentUser] = useState();
  const isApplication = useState({ id: "", appName: "", envName: "", description: "", endpoint: "",
    storageStatus: "", upTimeStatus: "",  memoryStatus: "", queryDate: "", notes: "" });
  const [data, setData] = useState([]);
  const [message, setMessage] = useState();
  const [activeApplication, setActiveApplication] = useState(isApplication);
  const [isLoadApplication, setLoadApplication] = useState(false);
  const [isLoadUpdateApplication, setLoadUpdateApplication] = useState(false);
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!activeApplication.appName) {
        newErrors.appName = "App Name is required";
        isValid = false;
    }

    if (!activeApplication.envName) {
        newErrors.storageEndpoint = "Env is required";
        isValid = false;
    }

    if (!activeApplication.description) {
      newErrors.description = "Description is required";
      isValid = false;
    }

    if (!activeApplication.endpoint) {
      newErrors.endpoint = "Endpoint is required";
      isValid = false;
    }

    if (!activeApplication.storageStatus) {
        newErrors.storageStatus = "Storage Status is required";
        isValid = false;
    }

    if (!activeApplication.upTimeStatus) {
        newErrors.upTimeStatus = "Uptime Status is required";
        isValid = false;
    }

    if (!activeApplication.memoryStatus) {
        newErrors.memoryStatus = "Memory Status is required";
        isValid = false;
      }

     if (!activeApplication.notes || activeApplication.notes) {
        isValid = true;
    }

    setErrors(newErrors);
    return isValid;
  };  

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  const memoryChart = () => {
    let counter = 0;
    let memData = [];
    let memoryData = {
    labels: ["", "", "", "", "", ""],
    datasets: [
      {
        label: "Memory",
        data: [], //33, 25, 35, 51, 54, 76
        fill: true,
        backgroundColor: "rgba(116,39,116,0.2)",
        borderColor: "#742774"
      }
    ],
    options: {
      responsive: true,
    }
  };

  setInterval(() => {
    let chart = memoryData;
    if (counter >= 36) {
      counter = 0;
      chart.datasets[0].data = new Array(counter);
    } else if (counter >= 6 && counter < 36) {
      counter++;
      let random = Math.floor(Math.random() * 10) + 1;
      chart.datasets[0].data.shift();
      chart.datasets[0].data.push(random);
      /* console.log(chart.datasets[0].data.length);
      console.log(chart.datasets[0].data); */
      setMemoryChartData(chart);
    } else {
      counter++;
      let random = Math.floor(Math.random() * 10) + 1;
      chart.datasets[0].data.push(random);
      /* console.log(chart.datasets[0].data.length);
      console.log(chart.datasets[0].data); */
      setMemoryChartData(chart);
    };
  }, 1000);

  };



  const runTimeChart = () => {
    let counter = 0;
    let runData = [];
    let runTimeData = {
    labels: ["", "", "", "", "", ""],
    datasets: [
      {
        label: "Uptime",
        data: [], //33, 53, 85, 41, 44, 65
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)"
      }
    ],
    options: {
      responsive: true,
    }
  };

  setInterval(() => {
    let chart = runTimeData;
    if (counter >= 36) {
      counter = 0;
      chart.datasets[0].data = new Array(counter);
    } else if (counter >= 6 && counter < 36) {
      counter++;
      let random = Math.floor(Math.random() * 10) + 1;
      chart.datasets[0].data.shift();
      chart.datasets[0].data.push(random);
      /* console.log(chart.datasets[0].data.length);
      console.log(chart.datasets[0].data); */
      setRunTimeChartData(chart);
    } else {
      counter++;
      let random = Math.floor(Math.random() * 10) + 1;
      chart.datasets[0].data.push(random);
      /* console.log(chart.datasets[0].data.length);
      console.log(chart.datasets[0].data); */
      setRunTimeChartData(chart);
    };
  }, 1000);

  };


  const apiChart = () => {
    let counter = 0;
    let apiData = [];
    let apiCallsData = {
    labels: ["", "", "", "", "", ""],
    datasets: [
      {
        label: "Api Calls",
        data: [], //33, 25, 35, 51, 54, 76
        fill: true,
        backgroundColor: "rgba(20,164,77,0.2)",
        borderColor: "#14A44D"
      }
    ],
    options: {
      responsive: true,
    }
  };
  

  setInterval(() => {
    let chart = apiCallsData;
    if (counter >= 36) {
      counter = 0;
      chart.datasets[0].data = new Array(counter);
    } else if (counter >= 6 && counter < 36) {
      counter++;
      let random = Math.floor(Math.random() * 10) + 1;
      chart.datasets[0].data.shift();
      chart.datasets[0].data.push(random);
      /* console.log(chart.datasets[0].data.length);
      console.log(chart.datasets[0].data); */
      setApiChartData(chart);
    } else {
      counter++;
      let random = Math.floor(Math.random() * 10) + 1;
      chart.datasets[0].data.push(random);
      /* console.log(chart.datasets[0].data.length);
      console.log(chart.datasets[0].data); */
      setApiChartData(chart);
    };
  }, 1000 * 60);

  };



  const storageChart = () => {
    let counter = 0;
    let storData = [];
    let storageData = {
    labels: ["", "", "", "", "", ""],
    datasets: [
      {
        label: "Storage",
        data: [], //33, 25, 35, 51, 54, 76
        fill: true,
        backgroundColor: "rgba(255,127,39,0.2)",
        borderColor: "rgba(255,127,39,1)"
      }
    ],
    options: {
      responsive: true,
    }
  };

  setInterval(() => {
    let chart = storageData;
    if (counter >= 36) {
      counter = 0;
      chart.datasets[0].data = new Array(counter);
    } else if (counter >= 6 && counter < 36) {
      counter++;
      let random = Math.floor(Math.random() * 10) + 1;
      chart.datasets[0].data.shift();
      chart.datasets[0].data.push(random);
      /* console.log(chart.datasets[0].data.length);
      console.log(chart.datasets[0].data); */
      setStorageChartData(chart);
    } else {
      counter++;
      let random = Math.floor(Math.random() * 10) + 1;
      chart.datasets[0].data.push(random);
      /* console.log(chart.datasets[0].data.length);
      console.log(chart.datasets[0].data); */
      setStorageChartData(chart);
    };
  }, 1000);

  };

  



  useEffect(() => {

    const userData = loggedInUser.loggedInUser;
    const appData = loggedInUser.data;

    setCurrentUser(userData);
    setData(appData);
    apiChart();
    memoryChart();
    storageChart();
    runTimeChart();
  


    setActiveApplication(activeApplication);


  }, [activeApplication, setCurrentUser, loggedInUser, setData]);


  const setViewActiveApplication = (application) => {
    setActiveApplication(application);
    setLoadApplication(true);
  };

  const setActiveApplicationById = (application) => {
    setActiveApplication(application);
  };


  const setUpdateActiveApplication = (application) => {
    setActiveApplication(application);
    setLoadUpdateApplication(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setActiveApplication((item) => ({
        ...item,
        [name]: value
    }));
    };

  const handleUpdateApp = async (e) => {
      e.preventDefault();

      if (validateForm()) { 
          ApplicationService.updateApplicationById(activeApplication._id, activeApplication.appName, activeApplication.envName, 
            activeApplication.description, activeApplication.endpoint, activeApplication.storageStatus, activeApplication.upTimeStatus, 
            activeApplication.memoryStatus, activeApplication.notes).then(
              () => {
                  setSuccess("Application Updated Successfully!");
                  setLoading(true);
                  console.log("Active Application:", activeApplication);
                  setSubmitted(true); 
                  setTimeout(() => {
                      setLoading(false);
                      setSuccess("");
                      //navigate("/applications");
                      window.location.reload();}, 2300)
              },
              (error) => {
                  const message =
                      (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                      error.message ||
                      error.toString();
  
                  setMessage(message);
              }
          );
      
        } else {
          // Form is not valid, display error messages
        } }


  const deleteConfirm = (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      deleteApplication(id);
    }
  };
  const deleteApplication = async (id) => {
    try {
      ApplicationService.deleteApplicationById(id)
        .then(() => {
          setActiveApplication([]);
          //ApplicationService.allApplications();
          window.location.reload();
          return;
        })
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      setMessage(message);
    }
  }

  

  return (
    <div>
      {currentUser ? (<div className="App">


        {/* <body> */}
        <link rel="stylesheet" href={require("../assets/css/style.css")}></link>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.min.css"></link>
        <link rel="stylesheet" href={require("../assets/css/bootstrap.min.css")}></link>
        <link rel="stylesheet" href={require("../assets/css/bootstrap-reboot.min.css")}></link>


        <section className="container-fluid">
          <section className="row">
            <aside className="sidebar sidebar-nav border border-right col-md-3 col-lg-2">
              <div className="offcanvas-md offcanvas-end bg-body-tertiary" tabIndex="-1" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
                <div className="offcanvas-header">
                  <h5 className="offcanvas-title" id="sidebarMenuLabel">Company name</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto">
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/home">
                        <i className="bi-house-fill"></i>
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="/applications">
                        <i className="bi bi-grid"></i><span>Applications</span><i className="bi bi-chevron-down ms-auto"></i>
                      </a>
                      {data && data.length >= 1 ? (
                        data.map((item, key) =>
                          <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                            <Link to={`../view-application/${item.id}`}>
                              <li className="nav-item" key={key} onClick={() => setViewActiveApplication(item, item.id)}>
                                {/* <i className="bi bi-circle"></i> */}
                                {(item.appName === "Ibima" || item.appName === "Bima" || item.appName === "Nanatec" || 
                                    item.appName === "Nexus" || item.appName === "Unitrust") ? 
                                    
                                  <img src={require(`../images/${item.appName}-logo.png`)} 
                                  alt="" style={{ width: "20px", height: "20px", borderRadius: "50%" }}/>
                                  
                                  : 
                                  <Avatar name={item.appName + " " + item.appName} size="20" round={true} />
                                }
                                <span> {item.appName}</span>
                              </li>
                            </Link>
                          </ul>)
                      ) : (
                        <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                          <li className="nav-item">
                            <span>No Apps Found!</span>
                          </li>
                        </ul>
                      )}
                    </li>
                    <li className="nav-item">
                      <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/users">
                        <i className="bi-people"></i>
                        Users
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </aside>

            <main className="dashboard col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="pagetitle">
                <h1>Applications</h1>
                <span style={{ display: "block", position: "relative", marginLeft: "700px" }}>
                <Link to={`../register`} style={{ textDecoration: "none" }}>
                  <button type="button" className="btn btn-primary"><i className="bi-file-plus"> Create App</i></button>
                </Link>
                </span>
                <nav className="breadcrumbs">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a className="breadcrumb-menu-a" href="/home">Home</a></li>
                    <li className="breadcrumb-item"><span style={{ backgroundColor: "#555", color: "white", borderRadius: "5px", padding: "5px" }}>Applications</span></li>
                  </ol>
                </nav>
              </div>
              <section className="section">

              </section>

              <section className="section dashboard">
                <div className="row">

                  <div className="col">
                    <div className="row">


                      {data && data.length >= 1 ? (
                        data.map((item, key) =>
                          <div className="col-xxl-6 col-xl-12" key={key}>
                            <div className="card info-card customers-card">

                              <div className="filter">
                                <span className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></span>
                                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                  <li className="dropdown-header text-start">
                                    <h6 style={{ textAlign: "center" }}>Actions</h6>
                                  </li>

                                  <Link to={`../view-application/${item.id}`} style={{ textDecoration: "none" }}>
                                    <li className="card-button-view-container"
                                      onClick={() => setViewActiveApplication(item, item.id)}>
                                      <button className="card-button-view"><BsEye style={{ fontSize: "20px" }}/> Monitor </button>
                                    </li>
                                  </Link>
                                  {/* <Link to={`../update-application/${item.id}`} style={{ textDecoration: "none" }}>
                                    <li className="card-button-edit-container"
                                      onClick={() => setUpdateActiveApplication(item, item.id)}>
                                      <button className="card-button-edit"><BsPencil style={{ fontSize: "20px" }}/> Update </button>
                                    </li>
                                  </Link> */}
                                    <li className="card-button-edit-container"
                                      onClick={() => { setActiveApplicationById(item, item.id); handleShowModal() }}>
                                      <button className="card-button-edit"><BsPencil style={{ fontSize: "20px" }}/> Update </button>
                                    </li>
                                  <li className="card-button-delete-container"
                                    onClick={() => { setActiveApplicationById(item.id); deleteConfirm(item.id) }}>
                                    <button className="card-button-delete"><BsTrash style={{ fontSize: "20px" }}/> Delete </button>
                                  </li>
                                </ul>
                              </div>

                              <div className="card-body">
                                <h5 className="card-title">Application Details<span> | Today</span></h5>

                                <div className="d-flex align-items-center">
                                  <div className="ps-3">
                                    <h6>
                                    {(item.appName === "Ibima" || item.appName === "Bima" || item.appName === "Nanatec" || 
                                      item.appName === "Nexus" || item.appName === "Unitrust") ? 
                                  
                                        <img src={require(`../images/${item.appName}-logo.png`)} 
                                        alt="" style={{ width: "20px", height: "20px", borderRadius: "50%", marginRight: "10px" }}/>
                                        
                                      : 
                                        <Avatar name={item.appName + " " + item.appName} size="70" round={true} />
                                      }
                                      {item.appName}
                                    </h6>

                                    <div className="list row" style={{ marginTop: "10px", boxSizing: "border-box", borderRadius: "3px", boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.1)" }}>

                                    <div className="col-xxl-4 col-md-4">

                                    <div style={{ marginTop: "10px", fontSize: "14px" }}>
                                    <h5 className="card-title">{item.livenessStatus === "Active" && <span className="badge rounded-pill bg-success" style={{ color: "white" }}>Live</span>} 
                                    {item.livenessStatus === "Inactive" && <span className="badge rounded-pill bg-danger" style={{ color: "white" }}>Not Live</span>} <span> | status</span></h5>

                                    <span className="small">
                                      {/* <div className="list-line-separator"></div>
                                      status: <span style={{ color: "#3751FF" }}> {item.livenessStatus === "Active" && <span className="badge rounded-pill bg-success">Live</span>} 
                                      {item.livenessStatus === "Inactive" && <span className="badge rounded-pill bg-danger">Not Live</span>}
                                      </span>  */}

                                      version: <span style={{ color: "#3751FF" }}> {item.version} </span> 
                                      <span style={{ marginLeft: "20px" }} />
                                      {/* runtime: <span style={{ color: "#3751FF" }}> {item.runTime} </span> 
                                      <span style={{ marginLeft: "20px" }} />
                                      api calls: <span style={{ color: "#3751FF" }}> {item.numberOfCalls} </span> 
                                      <span style={{ marginLeft: "20px" }} /> */}
                                      env name: <span style={{ color: "#3751FF" }}> {item.envName} </span>   
                                      <div className="list-line-separator"></div>

                                      {/* description: <span style={{ color: "#3751FF" }}> {item.description} </span> 
                                      <div className="list-line-separator"></div>

                                      endpoint: <span style={{ color: "#3751FF" }}> {item.endpoint} </span>   
                                      <div className="list-line-separator"></div> */}

                                      {/* storage: <span style={{ color: "#3751FF" }}> {item.storageUsed} / {item.storageTotal} GB ({ item.storageUsed / item.storageTotal * 100 }%)</span> <br />
                                      storage status: {item.storageStatus === "good" && <span className="badge rounded-pill bg-success" style={{ marginLeft: "5px" }}> {item.storageStatus}</span>}
                                      {item.storageStatus === "warning" && <span className="badge rounded-pill bg-warning" style={{ marginLeft: "5px" }}> {item.storageStatus}</span>}
                                      {item.storageStatus === "bad" && <span className="badge rounded-pill bg-danger" style={{ marginLeft: "5px" }}> {item.storageStatus}</span>}
                                      <div className="list-line-separator"></div>

                                      memory: <span style={{ color: "#3751FF" }}> {item.memoryUsed} / {item.memoryTotal}  GB ({ item.memoryUsed / item.memoryTotal * 100 }%)</span> <br />
                                      memory status: {item.memoryStatus === "good" && <span className="badge rounded-pill bg-success" style={{ marginLeft: "5px" }}> {item.memoryStatus}</span>}
                                      {item.memoryStatus === "warning" && <span className="badge rounded-pill bg-warning" style={{ marginLeft: "5px" }}> {item.memoryStatus}</span>}
                                      {item.memoryStatus === "bad" && <span className="badge rounded-pill bg-danger" style={{ marginLeft: "5px" }}> {item.memoryStatus}</span>}
                                      <div className="list-line-separator"></div> */}

                                      server type: <span style={{ color: "#3751FF" }}> {item.serverType} </span> <br />
                                      server OS: <span style={{ color: "#3751FF" }}> {item.serverOS} </span> 
                                      <div className="list-line-separator"></div>

                                      deployment type: <span style={{ color: "#3751FF" }}> {item.deploymentType} </span> <br />
                                      deployment provider: <span style={{ color: "#3751FF" }}> {item.deploymentProvider} </span>  <br />
                                      {/* <div className="list-line-separator"></div> */}

                                      {/* notes: <span style={{ color: "#3751FF" }}> {item.notes} </span> <br /> */}
                                    </span> 

                                    </div>

                                    </div>

                                    <div className="col-xxl-4 col-md-8">
                                    <div className="list-view">
                                    <h5 className="card-title" style={{ marginTop: "10px", }}><BsSpeedometer /> <span> | Application Performance Metrics</span></h5>
                                    {/* <div className="list-line-separator"></div> */}

                                    <div style={{ display: "block", boxSizing: "border-box", marginTop: "5px", marginRight: "0px", marginBottom: "5px", paddingTop: "10px", paddingRight: "5px", paddingBottom: "10px" }}>
                                    <Carousel
                                      additionalTransfrom={0}
                                      arrows
                                      autoPlaySpeed={3000}
                                      centerMode={false}
                                      className=""
                                      containerClass="container"
                                      dotListClass=""
                                      draggable
                                      focusOnSelect={false}
                                      infinite={false}
                                      itemClass=""
                                      keyBoardControl
                                      minimumTouchDrag={80}
                                      pauseOnHover
                                      renderArrowsWhenDisabled={false}
                                      renderButtonGroupOutside={false}
                                      renderDotsOutside={false}
                                      responsive={{
                                        desktop: {
                                          breakpoint: {
                                            max: 3000,
                                            min: 1024
                                          },
                                          items: 3,
                                          partialVisibilityGutter: 40
                                        },
                                        mobile: {
                                          breakpoint: {
                                            max: 464,
                                            min: 0
                                          },
                                          items: 1,
                                          partialVisibilityGutter: 30
                                        },
                                        tablet: {
                                          breakpoint: {
                                            max: 1024,
                                            min: 464
                                          },
                                          items: 2,
                                          partialVisibilityGutter: 30
                                        }
                                      }}
                                      rewind={false}
                                      rewindWithAnimation={false}
                                      rtl={false}
                                      shouldResetAutoplay
                                      showDots={false}
                                      sliderClass=""
                                      slidesToSlide={1}
                                      swipeable
                                    >
                                      <div style={{ display: "block", boxSizing: "border-box", marginTop: "5px", marginBottom: "5px", marginRight: "5px", borderRadius: "3px", boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.1)" }}>
                                        <div style={{ display: "flex", justifyContent: "space-around", height: "100px" }}>
                                          {runTimeChartData ? <Line data={runTimeChartData} /* style={{ height: "154px", width: "auto" }} *//> : null}
                                        </div>

                                        <div className="small">
                                          <span style={{ display: "block", textAlign: "center", fontSize: "10px" }}>
                                          <span>Runtime</span>  <br/>
                                          <span>{item.runTime}</span>
                                          </span>
                                        </div>
                                      </div>

                                      <div style={{ display: "block", boxSizing: "border-box", marginLeft: "5px", marginRight: "5px", marginTop: "5px", marginBottom: "5px", borderRadius: "3px", boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.1)" }}>
                                        <div style={{ display: "flex", justifyContent: "space-around", height: "100px" }}>
                                          {memoryChartData ? <Line data={memoryChartData} /* style={{ height: "154px", width: "auto" }} *//> : null}
                                        </div>

                                        <div className="small">
                                          <span style={{ display: "block", textAlign: "center", fontSize: "10px" }}>
                                          <span>Memory</span>  <br/>
                                          <span>{item.memoryUsed} / {item.memoryTotal} GB ({ item.memoryUsed / item.memoryTotal * 100 }%) </span>
                                          </span>
                                        </div>
                                      </div>

                                      <div style={{ display: "block", boxSizing: "border-box", marginLeft: "5px", marginRight: "5px", marginTop: "5px", marginBottom: "5px", borderRadius: "3px", boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.1)" }}>
                                        <div style={{ display: "flex", justifyContent: "space-around", height: "100px" }}>
                                          {storageChartData ? <Line data={storageChartData} /* style={{ height: "150px", width: "auto" }} *//> : null}
                                        </div>

                                        <div className="small">
                                          <span style={{ display: "block", textAlign: "center", fontSize: "10px" }}>
                                          <span>Storage</span>  <br/>
                                          <span>{item.storageUsed} / {item.storageTotal} GB ({ item.storageUsed / item.storageTotal * 100 }%) </span>
                                          </span>
                                        </div>
                                      </div>

                                      <div style={{ display: "block", boxSizing: "border-box", marginLeft: "5px", marginRight: "5px", marginTop: "5px", marginBottom: "5px", borderRadius: "3px", boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.1)" }}>
                                        <div style={{ display: "flex", justifyContent: "space-around", height: "100px" }}>
                                          {apiChartData ? <Line data={apiChartData} options={apiChartData.options}/* style={{ height: "154px", width: "auto" }} */ /> : null}
                                        </div>

                                        <div className="small">
                                          <span style={{ display: "block", textAlign: "center", fontSize: "10px" }}>
                                          <span>Api Calls</span>  <br/>
                                          <span>{item.numberOfCalls}</span>
                                          </span>
                                        </div>
                                      </div>

                                    </Carousel>
                                    </div>

                                    

                                    {/* <div style={{ display: "flex", justifyContent: "space-around", textAlign: "center" }}>

                                    <div style={{ display: "block" }}>
                                    <Line data={runTimeData} style={{ height: "100px", width: "auto" }}/>
                                    </div>

                                    <div className="small">
                                    <span style={{ display: "block", paddingTop: "30px" }}>
                                    <span>Runtime</span>  <br/>
                                    <span>{item.runTime}</span>
                                    </span>
                                    </div>

                                    </div> */}

                                    
                                    {/* <div style={{ display: "flex", justifyContent: "space-around", textAlign: "center" }}>

                                    <div style={{ display: "block" }}>
                                    <Line data={memoryData} style={{ height: "100px", width: "auto" }}/>
                                    </div>

                                    <div className="small">
                                    <span style={{ display: "block", paddingTop: "30px" }}>
                                    <span>Memory</span>  <br/>
                                    <span>{item.memoryUsed} / {item.memoryTotal} GB ({ item.memoryUsed / item.memoryTotal * 100 }%) </span>
                                    </span>
                                    </div>

                                    </div>

                                    
                                    <div style={{ display: "flex", justifyContent: "space-around", textAlign: "center" }}>

                                    <div style={{ display: "block", height: "100px", width: "auto" }}>
                                    <Line data={memoryData} style={{ height: "100px", width: "auto" }}/>
                                    <Pie data={storageDoughnutData} options={storageDoughnutOptions} />
                                    </div>

                                    <div className="small">
                                    <span style={{ display: "block", paddingTop: "30px" }}>
                                    <span>Storage</span>  <br/>
                                    <span>{item.storageUsed} / {item.storageTotal} GB ({ item.storageUsed / item.storageTotal * 100 }%) </span>
                                    </span>
                                    </div>

                                    </div>

                                    
                                    <div style={{ display: "flex", justifyContent: "space-around", textAlign: "center" }}>

                                    <div style={{ display: "block" }}>
                                    <Line data={apiCallsData} style={{ height: "100px", width: "auto" }}/>
                                    </div>

                                    <div className="small">
                                    <span style={{ display: "block", paddingTop: "30px" }}>
                                    <span>Api Calls</span>  <br/>
                                    <span>{item.numberOfCalls}</span>
                                    </span>
                                    </div>

                                    </div> */}

                                    </div>
                                    </div>

                                    {/* </div> */}

                                    <div className="col-md-12" style={{ backgroundColor: "#eceff7", /* width: "1000px", */ fontSize: "12px", paddingTop: "5px", paddingBottom: "5px", margin: "auto", marginTop: "10px" }}>
                                      created by: <span style={{ color: "#3751FF" }}> {item.createdBy} </span> <br />
                                      date created: <span style={{ color: "#3751FF" }}> {item.createdAt} </span>
                                    </div>

                                    </div>

                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>)
                      ) : (
                        <div className="col-xxl-6 col-xl-12">
                          <div className="col-md-12" style={{
                            backgroundColor: "#eceff7", fontSize: "14px", width: "1000px",
                            paddingTop: "10px", paddingBottom: "10px", margin: "auto", textAlign: "center"
                          }}>
                            No Apps to Display!
                          </div>
                        </div>
                      )}



                    </div>
                  </div>

                </div>
              </section>

              <div>
<Modal
        show={showModal}
        onHide={() => { handleCloseModal() }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          
          <div className="modal-header-sign-up" style={{ textAlign: "center" }}>
            <h5>Update Application - {activeApplication.appName}</h5>
            <p>Fill in the details below</p>
            {message && (<div className="error">{message}</div>)}
          </div>

        </Modal.Header>
        <Modal.Body>

        <div /* className="register-body" */>
                <div className="col-md-12">
        {loading && submitted ? (<div className="info">{success}</div> 
        ) : (
                    <form className="insertForm"
                        onSubmit={handleUpdateApp} >
                        <div className="register-column">
                            <label htmlFor="appName">App Name*</label>
                            <input
                                type="text"
                                name="appName"
                                className="form-control"
                                value={activeApplication.appName}
                                onChange={handleInputChange}
                                autoComplete="off"
                                required
                            />
                            {errors.appName && <div className="form-error">{errors.appName}</div>}
                        </div>
                        <div className="register-column">
                            <label htmlFor="envName">Environment Name*</label>
                            <input
                                type="text"
                                name="envName"
                                className="form-control"
                                value={activeApplication.envName}
                                onChange={handleInputChange}
                                autoComplete="off"
                                required
                            />
                            {errors.envName && <div className="form-error">{errors.envName}</div>}
                        </div>
                        <div className="register-column">
                            <label htmlFor="description">Description*</label>
                            <input
                                type="text"
                                name="description"
                                className="form-control"
                                value={activeApplication.description}
                                onChange={handleInputChange}
                                autoComplete="off"
                                required
                            />
                            {errors.description && <div className="form-error">{errors.description}</div>}
                        </div>
                        <div className="register-column">
                            <label htmlFor="endpoint">Endpoint*</label>
                            <input
                                type="text"
                                name="endpoint"
                                className="form-control"
                                value={activeApplication.endpoint}
                                onChange={handleInputChange}
                                autoComplete="off"
                                required
                            />
                            {errors.endpoint && <div className="form-error">{errors.endpoint}</div>}
                        </div>
                        <div className="register-column">
                            <label htmlFor="storageStatus">Storage Status*</label>
                            <select
                                type="text"
                                name="storageStatus"
                                className="form-control"
                                value={activeApplication.storageStatus}
                                onChange={handleInputChange}
                                autoComplete="off"
                                issearchable="true"
                                required
                            >
                                {statusEnum.map((item) => 
                                <option key={item.label} value={item.value}>{item.label}</option>)}
                            </select>
                            {errors.storageStatus && <div className="form-error">{errors.storageStatus}</div>}
                        </div>
                        <div className="register-column">
                            <label htmlFor="upTimeStatus">Uptime Status*</label>
                            <select
                                type="text"
                                name="upTimeStatus"
                                className="form-control"
                                value={activeApplication.upTimeStatus}
                                onChange={handleInputChange}
                                autoComplete="off"
                                issearchable="true"
                                required
                            >
                                {statusEnum.map((item) => 
                                <option key={item.label} value={item.value}>{item.label}</option>)}
                            </select>
                            {errors.upTimeStatus && <div className="form-error">{errors.upTimeStatus}</div>}
                        </div>
                        <div className="register-column">
                            <label htmlFor="memoryStatus">Memory Status*</label>
                            <select
                                type="text"
                                name="memoryStatus"
                                className="form-control"
                                value={activeApplication.memoryStatus}
                                onChange={handleInputChange}
                                autoComplete="off"
                                issearchable="true"
                                required
                            >
                                {statusEnum.map((item) => 
                                <option key={item.label} value={item.value}>{item.label}</option>)}
                            </select>
                            {errors.memoryStatus && <div className="form-error">{errors.memoryStatus}</div>}
                        </div>
                        <div className="register-column">
                            <label htmlFor="notes">Notes</label>
                            <input
                                type="text"
                                name="notes"
                                className="form-control"
                                value={activeApplication.notes}
                                onChange={handleInputChange}
                                placeholder="Optional"
                                autoComplete="off"
                            />
                            {errors.notes && <div className="form-error">{errors.notes}</div>}
                        </div>
                        <div className="register-button-area">
                            <button className="register-button" type="submit" /* disabled={!isFormValid} */>
                                Update App
                            </button>
                        </div>
                    </form>
                )}
                </div>
            </div>

        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
</div>


            </main>

          </section>
        </section>
        <div>
          {isLoadApplication &&
            <ViewApplication list={activeApplication} />
          }
        </div>
        <div>
          {isLoadUpdateApplication &&
            <UpdateApp list={activeApplication} />}
        </div>
      </div>
      ) : (
        
        <Login />
        
      )}
    </div>
  );
}

export default Applications;