import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const register = (firstName, lastName, username, phoneNumber, email, password, role) => {
  return axios.post(API_URL + `user-register`, 
  {
    firstName, lastName, username, phoneNumber, email, password, role
  },
  { }
  );
};

const firstTimeLogin = (email, password, OTP_Code) => {
  return axios
    .post(API_URL + `users/account-verification/`, {
      email, password, OTP_Code
    })
    .then((response) => {
      if (response.data) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const getOTP = (email) => {
  return axios
    .post(API_URL + `users/forgot-password/`, {
      email
    })
    .then((response) => {
      /* if (response.data) {
        localStorage.setItem("user", JSON.stringify(response.data));
      } */

      return response.data;
    });
};

const login = (email, password) => {


  console.log(" login url ", API_URL + "user-login")
  return axios
    .post(API_URL + "user-login", {
      email,
      password,
    })
    .then((response) => {
      if (response.data) {
        localStorage.setItem("user", JSON.stringify(response.data));
        /* setTimeout(() => {
          localStorage.removeItem("user");
          window.location.reload();}, 86400); */
      }

      return response.data;
    });
};

const recoverPasswordOTP = (email, password, OTP_Code) => {
  return axios
    .post(API_URL + `users/recover-password/`, {
      email, password, OTP_Code
    })
    .then((response) => {
      if (response.data) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};


const logout = () => {
  localStorage.removeItem("user");
};

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
}; 

/* const getCurrentUser = () => {

    const user = localStorage.getItem("user");} */
    /* if (user === undefined) {
      console.log(user)
      window.location.replace("/login");
      return;
    }  */
    /* if (user != undefined) {
    const userId = JSON.parse(localStorage.getItem("user"));
    const token = userId.tokens[userId.tokens.length - 1];
    const accessToken = token.token;

    if (userId && accessToken) {
      const decodedJwt = parseJwt(accessToken);


      if (decodedJwt.exp * 1000 < Date.now()) {
        localStorage.removeItem("user");
        window.location.replace("/login");
        return;
      }
    } 
    } };*/
  


const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};  

const AuthService = {
  register,
  firstTimeLogin,
  getOTP,
  login,
  recoverPasswordOTP,
  logout,
  getCurrentUser,
};

export default AuthService;