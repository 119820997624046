import React, { useEffect, useState } from "react";
import UserService from "../services/user-service/user-data-service";
import { useParams, useNavigate, Link } from "react-router-dom";
import { FaEnvelope, FaPhone } from "react-icons/fa";
import Avatar from 'react-avatar';
import { BsArrowDownRightSquare, BsPencil, BsTrash } from "react-icons/bs";
import Modal from 'react-bootstrap/Modal';
import ViewApplication from "./View-Application";

const rolesEnum = [
  { value: "", label: "", name: "" },
  { value: "Admin", label: "Admin", name: "Admin" },
  { value: "Developer", label: "Developer", name: "Developer" }
]

const statusEnum = [
  { value: "Active", label: "Active", name: "Active" },
  { value: "Pending", label: "Pending", name: "Pending" }
]

const ViewUser = (loggedInUser) => {

const params = useParams();
const navigate = useNavigate();

const [isLoadEditProfile, setLoadEditProfile] = useState(false);
const [isLoadEditPassword, setLoadEditPassword] = useState(false);
const [currentUser, setCurrentUser] = useState();
const [activeUser, setActiveUser] = useState([]);
const [message, setMessage] = useState(null);
const [notification, setNotification] = useState(null);
const [isLoading, setLoading] = useState(false);
const isApplication = useState({ id: "", appName: "", storageEndpoint: "", storageEndpointResponses: [],
  storageStatus: "", upTimeEndpoint: "", upTimeEndpointResponses: [], 
  upTimeStatus: "", memoryEndpoint: "", memoryEndpointResponses: [], memoryStatus: "",
  createdBy: "", notes: "" });
const [data, setData] = useState([]);
const [activeApplication, setActiveApplication] = useState(isApplication);
const [isLoadApplication, setLoadApplication] = useState(false);

const [showModal, setShowModal] = useState(false);
const handleCloseModal = () => setShowModal(false);
const handleShowModal = () => setShowModal(true);


useEffect(() => {
window.scrollTo(0, 0);

const userData = loggedInUser.loggedInUser;
const appData = loggedInUser.data;

setCurrentUser(userData);
setData(appData);

UserService.viewUserById(params.id).then(
  (response) => { 
    const data = response.data;
    if (data) {
      setActiveUser(data);
      return;
    };
  },
  (error) => {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();

    setMessage(message)
  }
);

/* ApplicationService.allApplications().then(
  (response) => {
    const data = response.data.reverse();
    const postData = data.map(item => ({
      id: item._id, appName: item.appName, storageEndpoint: item.storageEndpoint, storageEndpointResponses: item.storageEndpointResponses,
      storageStatus: item.storageStatus, upTimeEndpoint: item.upTimeEndpoint, upTimeEndpointResponses: item.upTimeEndpointResponses, 
      upTimeStatus: item.upTimeStatus, memoryEndpoint: item.memoryEndpoint, memoryEndpointResponses: item.memoryEndpointResponses, 
      memoryStatus: item.memoryStatus, createdBy: item.createdBy, notes: item.notes
    }))
    setData(postData);
  },
  (error) => {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();

    setMessage(message)
  }
); */


setActiveApplication(activeApplication);


}, [params, activeApplication, setCurrentUser, setActiveUser, loggedInUser, setData]);


const setViewActiveApplication = (application) => {
  setActiveApplication(application);
  setLoadApplication(true);
};


const handleChange = (e) => {
  const {name, value} = e.target;
  setActiveUser((item) => ({
      ...item, 
      [name]: value }));
  setNotification("");
};


const editUserById = async (e) => {
  e.preventDefault();

  UserService.updateUserById(params.id, activeUser.firstName, activeUser.lastName, activeUser.username, 
    activeUser.phoneNumber, activeUser.role, activeUser.status).then(
    () => {
      setLoading(true);
      setNotification("Update Successfull!");
      setTimeout(() => {
        setLoading(false);
        setLoadEditProfile(false);
        UserService.viewUserById(params.id);
        window.location.reload();
      }, 3300);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

        setLoading(true);
        setMessage(message);
        setTimeout(() => {
        setLoading(false);}, 4300);
    }
  );
};

const generatePasswordResetOTP_Code = () => {
  UserService.generatePasswordResetOTP_CodeById(params.id).then(
    (response) => {  
      setActiveUser(response.data);
      setLoadEditPassword(true);
      setLoading(true);
      setNotification("OTP successfully sent to your email for password change!");
      setTimeout(() => {
        setLoading(false);
      }, 4300);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
  
        setLoading(true);
        setMessage(message);
        setTimeout(() => {
        setLoading(false);}, 4300);
    }
  );
}

const updateUserPassword = async (e) => {
  e.preventDefault();

  UserService.updateUserPasswordById(params.id, activeUser.OTP_Code, activeUser.password).then(
    () => {
      setLoading(true);
      setNotification("Password Updated Successfully!");
      setTimeout(() => {
        setLoading(false);
        setLoadEditPassword(false);
        UserService.viewUserById(params.id);
        window.location.reload();
      }, 3300);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

        setLoading(true);
        setMessage(message);
        setTimeout(() => {
        setLoading(false);}, 4300);
    }
  );
};


const deleteConfirm = (id) => {
  if (window.confirm("Are you sure you want to delete this user? ")) {
    deleteUser(id);
  }
};
const deleteUser = async () => {
  try {
    UserService.deleteUserById(params.id)
      .then(() => {
        setLoading(true);
        setNotification("User Deleted Successfully!");
        setTimeout(() => {
          setLoading(false);
          setCurrentUser([]);
          navigate("/users");
          window.location.reload();
        }, 3300);
      })
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();

      setLoading(true);
      setMessage(message);
      setTimeout(() => {
      setLoading(false);}, 4300);
  }
}


  return (
  <div className="App">
    {currentUser ? (<div>

      <link rel="stylesheet" href={require("../assets/css/style.css")}></link>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.min.css"></link>
      <link rel="stylesheet" href={require("../assets/css/bootstrap.min.css")}></link>
      <link rel="stylesheet" href={require("../assets/css/bootstrap-reboot.min.css")}></link>

      <section className="container-fluid">
        <section className="row">
          <aside className="sidebar sidebar-nav border border-right col-md-3 col-lg-2">
            <div className="offcanvas-md offcanvas-end bg-body-tertiary" tabIndex="-1" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="sidebarMenuLabel">Company name</h5>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu" aria-label="Close"></button>
              </div>
              <div className="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto">
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/">
                    <i className="bi-house-fill"></i>
                    Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="/applications">
                      <i className="bi bi-grid"></i><span>Applications</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    {data && data.length >= 1 ? (
                      data.map((item, key) =>
                    <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                      <Link to={`../view-application/${item.id}`}>
                      <li className="nav-item" key={key} onClick={ () => setViewActiveApplication(item, item.id) }>
                        {/* <i className="bi bi-circle"></i> */}
                        {(item.appName === "Ibima" || item.appName === "Bima" || item.appName === "Nanatec" || 
                            item.appName === "Nexus" || item.appName === "Unitrust") ? 
                            
                          <img src={require(`../images/${item.appName}-logo.png`)} 
                          alt="" style={{ width: "20px", height: "20px", borderRadius: "50%" }}/>
                          
                          : 
                          <Avatar name={item.appName + " " + item.appName} size="20" round={true} />
                        }
                        <span> {item.appName}</span>
                      </li>
                      </Link>
                    </ul>)
                  ) : (
                    <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                      <li className="nav-item">
                        <span>No Apps Found!</span>
                      </li>
                    </ul>
                  )}
                    </li>
                    <li className="nav-item">
                    <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/users">
                    <i className="bi-people"></i>
                    Users
                    </a>
                  </li>
                  </ul>
              </div>
            </div>
          </aside>

          <main className="dashboard col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="pagetitle">
                {/* <h1>Dashboard</h1> */}
                <nav className="breadcrumbs">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a className="breadcrumb-menu-a" href="/">Home</a></li>
                    <li className="breadcrumb-item"><a className="breadcrumb-menu-a" href="/applications">Applications</a></li>
                    <li className="breadcrumb-item"><a className="breadcrumb-menu-a" href="/users">Users</a></li>
                    <li className="breadcrumb-item"><span style={{ backgroundColor: "#555", color: "white", borderRadius: "5px", padding: "5px" }}>{activeUser.firstName}</span></li>
                  </ol>
                </nav>
              </div>

          {/* </section>
          </section> */}

      <div className="col-lg">
      <div className="row">
        <div className="profile-card-full">
        <div className="profile-card-full-left-card">
              <div className="profile-card-full-header-stat">
                <h4>Profile</h4>
              </div>
              <div className="profile-picture-container">
                <Avatar name={activeUser.firstName + " " + activeUser.lastName} />
              </div>
              {(currentUser.role === "Admin" || currentUser.role === "Super Admin") && <div className="small" style={{ marginTop: "10px", textAlign: "center" }}>
                <div className="config-list-button-view-container"> Edit <br />
                  <button className="config-list-button-edit"
                    onClick={() => { setLoadEditProfile(true); handleShowModal() }}
                  ><BsPencil style={{ fontSize: "18px" }} /> </button>
                </div>
                <div className="config-list-button-warning-container"> Change Password <br />
                  <button className="config-list-button-warning" 
                    onClick={() => { generatePasswordResetOTP_Code(); handleShowModal() }}
                  ><BsArrowDownRightSquare style={{ fontSize: "18px" }} /> </button>
                </div>
                <div className="config-list-button-delete-container"> Delete <br />
                  <button className="config-list-button-delete"
                  onClick={deleteConfirm}
                  ><BsTrash style={{ fontSize: "18px" }} /> </button>
                </div>
              </div>}
              </div>
              <div className="profile-card-full-right-card">
              <div className="profile-card-full-header-stat" style={{ textAlign: "left" }}>
                <h4>{activeUser.firstName + " " + activeUser.lastName}</h4>
                <div className="list-line-separator"></div>
              </div>
              <div className="small" style={{ textAlign: "left" }}>
              <p className="profile-title"><strong style={{ color: "#555" }}>Role: </strong> {activeUser.role}</p>
              {activeUser.status === "Active" && <p><strong style={{ color: "#555" }}>Status: </strong>
                <span className="badge rounded-pill bg-success">{activeUser.status}</span>
              </p>}
              {activeUser.status === "Pending" && <p><strong style={{ color: "#555" }}>Status: </strong>
                <span className="badge rounded-pill bg-warning">{activeUser.status}</span>
              </p>}
              <span className="small"><FaPhone /> <strong style={{ color: "#555" }}> Phone: </strong>  +(254) {activeUser.phoneNumber} <br /> <br />
                <span><FaEnvelope /> <strong style={{ color: "#555" }}> Email: </strong>  <span style={{ color: "#3B71CA" }}> {activeUser.email} </span><br /></span>
              </span>
              </div>
              </div>
          {/* <div className="profile-card-full-header-stat">
            <h4>Profile</h4>
          </div>
          <div className="profile-picture-container">
            <Avatar name={activeUser.firstName + " " + activeUser.lastName} />
            </div>
          <h6>{activeUser.firstName + " " + activeUser.lastName}</h6>
          <p className="profile-title">{activeUser.role}</p>
          {activeUser.status === "Active" && <p className="small"><strong style={{color:"#555"}}>Status: </strong>
            <span className="badge rounded-pill bg-success">{activeUser.status}</span>
          </p>}
          {activeUser.status === "Pending" && <p className="small"><strong style={{color:"#555"}}>Status: </strong>
            <span className="badge rounded-pill bg-warning">{activeUser.status}</span>
          </p>}
          <span className="small" ><FaPhone /> +(254) {activeUser.phoneNumber} <br />
          <span><FaEnvelope /> <span style={{color: "#3B71CA"}}> {activeUser.email}</span> <br /></span>
          </span>
          {(currentUser.role === "Admin" || currentUser.role === "Super Admin") && <div className="small">
          <button className="config-list-button-edit" 
          onClick={() => { setLoadEditProfile(true); handleShowModal() }}
          ><BsPencil /> Edit</button>
          <button className="config-list-button-view" style={{ backgroundColor: "yellowgreen" }}
          onClick={() => { generatePasswordResetOTP_Code(); handleShowModal() }}
          ><BsArrowDownRightSquare /> Change Password</button>
          <button className="config-list-button-delete"
          onClick={deleteConfirm}
          ><BsTrash /> Delete</button>
          </div>} */}
        </div>

          <div>

          <Modal
        show={showModal}
        onHide={() => { setLoadEditProfile(false); setLoadEditPassword(false); setNotification(null); setMessage(null); handleCloseModal() }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          
          <div className="modal-header-sign-up">
            <h5>Update User Profile</h5>
            <p>Fill in the details below</p>
            {isLoading && message && (<div className="error"> {message} </div>)}
            {isLoading && notification && (<div className="success"> {notification} </div>)}
          </div>

        </Modal.Header>
        <Modal.Body>

        {activeUser && isLoadEditProfile && 
          <div className="modal-body-sign-up">
            <div className="col-md-12">
              <form className="insertForm"
                onSubmit={editUserById} >
                <div className="modal-sign-up-column">
                  <label htmlFor="firstName">First Name*</label>
                  <input
                    type="text"
                    name="firstName"
                    className="form-control"
                    value={activeUser.firstName}
                    onChange={handleChange}
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="modal-sign-up-column">
                  <label htmlFor="lastName">Last Name*</label>
                  <input
                    type="text"
                    name="lastName"
                    className="form-control"
                    value={activeUser.lastName}
                    onChange={handleChange}
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="modal-sign-up-column">
                  <label htmlFor="phoneNumber">Phone Number*</label>
                  <input
                    type="text"
                    name="phoneNumber"
                    className="form-control"
                    value={activeUser.phoneNumber}
                    onChange={handleChange}
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="modal-sign-up-column">
                  <label htmlFor="username">Username (optional)</label>
                  <input
                    type="text"
                    name="username"
                    className="form-control"
                    value={activeUser.username}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </div>
                <div className="modal-sign-up-column">
                  <label htmlFor="role">Role</label>
                  <select
                    name="role"
                    className="form-control"
                    value={activeUser.role} 
                    onChange={handleChange}
                    placeholder="Select A Role"
                    autoComplete="off"
                    issearchable="true"
                    required
                  >
                    <option value=""></option>
                    {rolesEnum.map((role) => 
                    <option key={role.value} value={role.value}>{role.label}</option>)}
                  </select>
                </div>
                <div className="modal-sign-up-column">
                  <label htmlFor="status">Status</label>
                  <select
                    name="status"
                    className="form-control"
                    value={activeUser.status} 
                    onChange={handleChange}
                    placeholder="Select A Status"
                    autoComplete="off"
                    issearchable="true"
                    required
                  >
                    {statusEnum.map((status) => 
                    <option key={status.value} value={status.value}>{status.label}</option>)}
                  </select>
                </div>
                <div className="register-button-area">
                  <button className="register-button" type="submit">
                    Update User Profile
                  </button>
                </div>
              </form>
            </div>
          </div>}

                  {activeUser && isLoadEditPassword && 
          <div className="modal-body-sign-up">
            <div className="col-md-12">
              <form className="insertForm"
                onSubmit={updateUserPassword} >
                <div className="modal-sign-up-column">
                  <label htmlFor="OTP_Code">OTP Code*</label>
                  <input
                    type="text"
                    name="OTP_Code"
                    className="form-control"
                    value={activeUser.OTP_Code}
                    onChange={handleChange}
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="modal-sign-up-column">
                  <label htmlFor="password">New Password*</label>
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    value={activeUser.password}
                    onChange={handleChange}
                    autoComplete="off"
                    required
                  />
                </div>
                <div style={{marginTop: "10px", marginLeft: "20px"}}>
                  Did not receive OTP-Code? <button style={{paddingTop: "10px", marginTop: "10px", border: "0px", color: "blue", textDecorationLine: "underline"}}
                  onClick={generatePasswordResetOTP_Code}
                            > Click Here</button>
                </div>
                <div className="register-button-area">
                  <button className="register-button" type="submit">
                    Update User Password
                  </button>
                </div>
              </form>
            </div>
          </div>}

        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>

        
      </Modal>

          </div>


        {/* <div>
        <CreateModal showCreate={showCreate} handleClose={() => { handleCloseCreateModal(); 
          setLoadEditProfile(false); setLoadEditPassword(false); setNotification("") }}>
          {activeUser && isLoadEditProfile && <div>
            <div className="modal-header-sign-up" align="center">
            <h5>Update User Profile</h5>
            <p>Fill in the details below</p>
          </div>
          </div>}
          {activeUser && isLoadEditPassword && <div>
            <div className="modal-header-sign-up" align="center">
            <h5>Update User Password</h5>
            <p>Fill in the details below</p>
            {notification && (<div className="success"> {notification} </div>)}
          </div>
          </div>}
        </CreateModal>
      </div> */}

      {message && (<div /* className="error" */>{message}</div>)}
      </div>
      <div>
      {isLoadApplication &&
          <ViewApplication list={activeApplication} />
        }
        </div>
    </div>
    </main>
    </section>
    </section>
    </div>
    ) : (
      null
    )}
  </div>
  );
};

export default ViewUser;