import React, { useEffect, useState } from "react";
import ApplicationService from "../services/application-service/application-data-service";
import '../App.css';
import { Link } from "react-router-dom";
import ViewApplication from "./View-Application";
import UpdateApp from "./UpdateApp";
import Avatar from "react-avatar";
import Login from "./Login";
import "bootstrap/dist/js/bootstrap.bundle.js";
//import Chart from "chart.js/auto";
import Chart from "react-apexcharts";
import { Line, Doughnut, Pie } from 'react-chartjs-2';
import { BsEye, BsPencil, BsTrash } from "react-icons/bs";
import Modal from 'react-bootstrap/Modal';

const statusEnum = [
  { value: "good", label: "good" },
  { value: "warning", label: "warning" },
  { value: "bad", label: "bad" }
]

const Home = (loggedInUser) => {


  const [currentUser, setCurrentUser] = useState();
  const isApplication = useState({
    id: "", appName: "", storageEndpoint: "", storageEndpointResponses: [],
    storageStatus: "", upTimeEndpoint: "", upTimeEndpointResponses: [],
    upTimeStatus: "", memoryEndpoint: "", memoryEndpointResponses: [], memoryStatus: "",
    createdBy: "", notes: ""
  });
  const [runTimeChartData, setRunTimeChartData] = useState();
  const [memoryChartData, setMemoryChartData] = useState();
  const [storageChartData, setStorageChartData] = useState();
  const [allChartData, setAllChartData] = useState();
  const [data, setData] = useState([]);
  const [memoryData, setMemoryData] = useState([35, 51, 49, 62, 69, 51]);
  const [storageData, setStorageData] = useState([25, 61, 29, 42, 59, 51,]);
  const [live, setLive] = useState();
  const [notLive, setNotLive] = useState();
  const [unhealthy, setUnhealthy] = useState();
  const [totalData, setTotalData] = useState(0);
  const [totalLive, setTotalLive] = useState(0);
  const [totalNotLive, setTotalNotLive] = useState(0);
  const [totalUnhealthy, setTotalUnhealthy] = useState(0);
  const [message, setMessage] = useState();
  const [activeApplication, setActiveApplication] = useState(isApplication);
  const [isLoadApplication, setLoadApplication] = useState(false);
  const [isLoadUpdateApplication, setLoadUpdateApplication] = useState(false);
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!activeApplication.appName) {
        newErrors.appName = "App Name is required";
        isValid = false;
    }

    if (!activeApplication.envName) {
        newErrors.storageEndpoint = "Env is required";
        isValid = false;
    }

    if (!activeApplication.description) {
      newErrors.description = "Description is required";
      isValid = false;
    }

    if (!activeApplication.endpoint) {
      newErrors.endpoint = "Endpoint is required";
      isValid = false;
    }

    if (!activeApplication.storageStatus) {
        newErrors.storageStatus = "Storage Status is required";
        isValid = false;
    }

    if (!activeApplication.upTimeStatus) {
        newErrors.upTimeStatus = "Uptime Status is required";
        isValid = false;
    }

    if (!activeApplication.memoryStatus) {
        newErrors.memoryStatus = "Memory Status is required";
        isValid = false;
      }

     if (!activeApplication.notes || activeApplication.notes) {
        isValid = true;
    }

    setErrors(newErrors);
    return isValid;
  };  

  const fullChartData = () => {
    let chartData = {
    labels: ["", "", "", "", "", ""],
    datasets: [
      {
        label: "Storage",
        data: [], //33, 53, 85, 41, 44, 65
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
        lineTension: 0.4
      },
      {
        label: "Uptime",
        data: [], //23, 83, 35, 61, 34, 85
        fill: false,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "#14A44D",
        lineTension: 0.4
      },
      {
        label: "Memory",
        data: [], //33, 25, 35, 51, 54, 76
        fill: false,
        borderColor: "#742774",
        lineTension: 0.4
      }
    ]
  };

  setInterval(() => {
    let counter = 0;
    let chart = chartData;
    if (counter >= 36) {
      counter = 0;
      chart.datasets[0].data = new Array(counter);
      chart.datasets[1].data = new Array(counter);
      chart.datasets[2].data = new Array(counter);
    } else if (counter >= 6 && counter < 36) {
      counter++;
      let random = Math.floor(Math.random() * 10) + 1;
      let random1 = Math.floor(Math.random() * 20) + 1;
      let random2 = Math.floor(Math.random() * 30) + 1;
      chart.datasets[0].data.shift();
      chart.datasets[1].data.shift();
      chart.datasets[2].data.shift();
      chart.datasets[0].data.push(random);
      chart.datasets[1].data.push(random1);
      chart.datasets[2].data.push(random2);
      /* console.log(chart.datasets[0].data.length);
      console.log(chart.datasets[0].data); */
      setAllChartData(chart);
    } else {
      counter++;
      let random = Math.floor(Math.random() * 10) + 1;
      let random1 = Math.floor(Math.random() * 20) + 1;
      let random2 = Math.floor(Math.random() * 30) + 1;
      chart.datasets[0].data.push(random);
      chart.datasets[1].data.push(random1);
      chart.datasets[2].data.push(random2);
      /* console.log(chart.datasets[0].data.length);
      console.log(chart.datasets[0].data); */
      setAllChartData(chart);
    };
  }, 5000);

  };

  const series = [
    {
      name: "Memory",
      data: memoryData
    },
    {
      name: "Storage",
      data: storageData
    }
  ];

  const options = {
    chart: {
      id: "realtime",
      height: 250,
      fontFamily: "Satoshi, sans-serif",
      type: "area",
      animations: {
        enabled: true,
        easing: "linear",
        dynamicAnimation: {
          speed: 2000
        }
      },
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    stroke: {
      width: [2, 2],
      curve: "smooth"
    },
    /* title: {
      text: "Dynamic Updating Chart",
      align: "center"
    }, */
    markers: {
      size: 3,
      colors: "#fff",
      strokeColors: ["#3056D3", "#80CAEE"],
      strokeWidth: 3,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      hover: {
        size: undefined,
        sizeOffset: 5,
      },
    },
    /* yaxis: {
      max: yaxisInterval
    }, */
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      title: {
        text: "Timeline",
        style: {
          fontSize: "14px",
          color: "#555",
          fontWeight: "500"
        },
      },
    },
    yaxis: {
      title: {
        text: "(GB)",
        style: {
          fontSize: "14px",
          color: "#555",
          fontWeight: "500"
        },
      },
    },
    legend: {
      show: true
    }
  };


  useEffect(() => {
    
    const userData = loggedInUser.loggedInUser;
    const appData = loggedInUser.data;

    setCurrentUser(userData);
    setActiveApplication(activeApplication);
    //fullChartData();

    if (appData) {
      setData(appData);
      setTotalData(appData.length);
      const live = appData.filter((item) => { 
        return item.livenessStatus === "Active" 
      });
      setLive(live);
      setTotalLive(live.length);
      const notLive = appData.filter((item) => { 
        return item.livenessStatus === "Inactive" 
      });
      setNotLive(notLive);
      setTotalNotLive(notLive.length);
      const unhealthy = live.filter((item) => { 
        return item.memoryUsed / item.memoryTotal > 0.8 || item.storageyUsed / item.storageTotal > 0.8 
      });
      setUnhealthy(unhealthy);
      setTotalUnhealthy(unhealthy.length);
    } else {
      return null;
    };

    const interval = setInterval(() => {
      const usedMemoryData = Math.floor(Math.random() * (100 - 30 + 1)) + 30;
      const usedStorageData = Math.floor(Math.random() * (100 - 20 + 1)) + 30;
      let memoryArray = [...memoryData, usedMemoryData];
      let storageArray = [...storageData, usedStorageData];
      memoryArray.shift();
      storageArray.shift();
      setMemoryData(memoryArray);
      setStorageData(storageArray);
    }, 2000);
    return () => {
      window.clearInterval(interval); // clear the interval in the cleanup function
    };
    
    /* ApplicationService.allApplications().then(
      (response) => {
        const data = response.data.reverse();
        const postData = data.map(item => ({
          id: item._id, appName: item.appName, storageEndpoint: item.storageEndpoint, storageEndpointResponses: item.storageEndpointResponses,
          storageStatus: item.storageStatus, upTimeEndpoint: item.upTimeEndpoint, upTimeEndpointResponses: item.upTimeEndpointResponses,
          upTimeStatus: item.upTimeStatus, memoryEndpoint: item.memoryEndpoint, memoryEndpointResponses: item.memoryEndpointResponses,
          memoryStatus: item.memoryStatus, createdBy: item.createdBy, notes: item.notes
        }))
        setData(postData);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setMessage(message)
      }
    ); */


    


  }, [activeApplication, setCurrentUser, loggedInUser, setData, memoryData, storageData]);


  const setViewActiveApplication = (application) => {
    setActiveApplication(application);
    setLoadApplication(true);
  };

  const setUpdateActiveApplication = (application) => {
    setActiveApplication(application);
    setLoadUpdateApplication(true);
  };

  const setActiveApplicationById = (application) => {
    setActiveApplication(application);
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setActiveApplication((item) => ({
        ...item,
        [name]: value
    }));
    };

  const handleUpdateApp = async (e) => {
      e.preventDefault();

      if (validateForm()) { 
          ApplicationService.updateApplicationById(activeApplication._id, activeApplication.appName, activeApplication.envName, 
            activeApplication.description, activeApplication.endpoint, activeApplication.storageStatus, activeApplication.upTimeStatus, 
            activeApplication.memoryStatus, activeApplication.notes).then(
              () => {
                  setSuccess("Application Updated Successfully!");
                  setLoading(true);
                  console.log("Active Application:", activeApplication);
                  setSubmitted(true); 
                  setTimeout(() => {
                      setLoading(false);
                      setSuccess("");
                      //navigate("/applications");
                      window.location.reload();}, 2300)
              },
              (error) => {
                  const message =
                      (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                      error.message ||
                      error.toString();
  
                  setMessage(message);
              }
          );
      
        } else {
          // Form is not valid, display error messages
        } }


  const deleteConfirm = (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      deleteApplication(id);
    }
  };
  const deleteApplication = async (id) => {
    try {
      ApplicationService.deleteApplicationById(id)
        .then(() => {
          setActiveApplication([]);
          ApplicationService.allApplications();
          window.location.reload();
          return;
        })
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      setMessage(message);
    }
  };



  return (
    <div className="App">
      {currentUser && data ? (<div>

        {/* <script src="../assets/js/bootstrap.bundle.min.js"></script> */}
        <link rel="stylesheet" href={require("../assets/css/style.css")}></link>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.min.css"></link>
        <link rel="stylesheet" href={require("../assets/css/bootstrap.min.css")}></link>
        <link rel="stylesheet" href={require("../assets/css/bootstrap-reboot.min.css")}></link>


        <section className="container-fluid">
          <section className="row">
            <aside className="sidebar sidebar-nav border border-right col-md-3 col-lg-2">
              <div className="offcanvas-md offcanvas-end bg-body-tertiary" tabIndex="-1" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
                <div className="offcanvas-header">
                  <h5 className="offcanvas-title" id="sidebarMenuLabel">Company name</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto">
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/home">
                        <i className="bi-house-fill"></i>
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="/applications">
                        <i className="bi bi-grid"></i><span>Applications</span><i className="bi bi-chevron-down ms-auto"></i>
                      </a>
                      {data && data.length >= 1 ? (
                        data.map((item, key) =>
                          <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                            <Link to={`../view-application/${item.id}`}>
                              <li className="nav-item" key={key} onClick={() => setViewActiveApplication(item, item.id)}>
                                {/* <i className="bi bi-circle"></i> */}
                                {(item.appName === "Ibima" || item.appName === "Bima" || item.appName === "Nanatec" || 
                                      item.appName === "Nexus" || item.appName === "Unitrust") ? 
                                    
                                  <img src={require(`../images/${item.appName}-logo.png`)} 
                                  alt="" style={{ width: "20px", height: "20px", borderRadius: "50%" }}/>
                                  
                                  : 
                                  <Avatar name={item.appName + " " + item.appName} size="20" round={true} />
                                }
                                <span> {item.appName}</span> 
                              </li>
                            </Link>
                          </ul>)
                      ) : (
                        <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                          <li className="nav-item">
                            <span>No Apps Found!</span>
                          </li>
                        </ul>
                      )}
                    </li>
                    <li className="nav-item">
                      <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/users">
                        <i className="bi-people"></i>
                        Users
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </aside>
            <main className="dashboard col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="pagetitle">
                {/* <h1>Dashboard</h1> */}
                <nav className="breadcrumbs">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item" style={{ backgroundColor: "#555", color: "white", borderRadius: "5px", padding: "5px" }}>Home</li>
                    <li className="breadcrumb-item"><a className="breadcrumb-menu-a" href="/applications">Applications</a></li>
                    <li className="breadcrumb-item"><a className="breadcrumb-menu-a" href="/users">Users</a></li>
                  </ol>
                </nav>
              </div>
              <section className="section">

              </section>

              <section className="section dashboard">
                <div className="row">

                  {/* Left side columns */}
                  <div className="col-lg">
                    <div className="row">

                      {/* Customers Card */}
                      <div className="col-xxl-4 col-xl-12">

                        <div className="card info-card customers-card">

                          <div className="filter" style={{ marginRight: "20px" }}>
                            <Link to={`../register`}>
                              <button className="btn btn-primary" /* onClick={ () => setLoadRegisterApplication(true) } */>
                                <i className="bi-file-plus"> Create App</i>
                              </button>
                            </Link>
                          </div>

                          <div className="card-body">
                            <h5 className="card-title">Applications <span>| Total | Live | Not Live </span></h5>

                            <div className="d-flex align-items-center" >

                              <div className="d-flex align-items-center justify-content-center"/* style={{ display: "flex", position: "relative", marginLeft: "10px", marginRight: "40px" }} */>
                              <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                <span className="text-primary"><i className="bi bi-app-indicator"></i></span>
                              </div>
                              <div className="ps-3">
                                <h6 className="text-primary">{totalData}</h6>
                                <span className="text-primary small pt-1 fw-bold">Total Apps</span> <span className="text-muted small pt-2 ps-1"></span>
                              </div>
                              </div>

                              <div className="d-flex align-items-center justify-content-center" /* style={{ display: "flex", position: "relative", marginLeft: "40px", marginRight: "40px" }} */>
                              <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                <span className="text-success"><i className="bi bi-heart-pulse"></i></span>
                              </div>
                              <div className="ps-3">
                                <h6 className="text-success">{totalLive}</h6> 
                                <span className="text-success small pt-1 fw-bold">Live</span>
                                <span style={{ display: "block", fontSize: "12px" }}><span className="small">Healthy. No action needed!</span></span>
                              </div>
                              </div>

                              {/* <div style={{ display: "flex", position: "relative", marginLeft: "40px", marginRight: "40px" }}>
                              <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                <span className="text-warning"><i className="bi bi-heart-pulse"></i></span>
                              </div>
                              <div className="ps-3">
                                <h6 className="text-warning">{totalUnhealthy}</h6>
                                <span className="text-warning small pt-1 fw-bold">Live & Unhealthy</span>
                                <span style={{ display: "block", fontSize: "12px" }}><span className="small">Checkup needed soon!</span></span>
                              </div>
                              </div> */}

                              <div className="d-flex align-items-center justify-content-center"/* style={{ display: "flex", position: "relative", marginLeft: "40px", marginRight: "40px" }} */>
                              <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                <span className="text-danger"><i className="bi bi-heart-pulse"></i></span>
                              </div>
                              <div className="ps-3">
                                <h6 className="text-danger">{totalNotLive}</h6>
                                <span className="text-danger small pt-1 fw-bold">Not Live</span>  
                                <span style={{ display: "block", fontSize: "12px" }}><span className="small">Critical. Attention needed immediately!</span></span>
                              </div>
                              </div>

                            </div>

                          </div>
                        </div>

                      </div> {/* End Customers Card */}

                      {data && data.length >= 1 ? (
                        data.map((item, key) =>
                          <div className="col-xxl-4 col-md-6" key={key}>

                            <div className="card info-card sales-card">

                              <div className="filter">
                                <span className="icon" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></span>
                                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                  <li className="dropdown-header text-start">
                                    <h6 style={{ textAlign: "center" }}>Actions</h6>
                                  </li>

                                  <Link to={`../view-application/${item.id}`} style={{ textDecoration: "none" }}>
                                    <li className="card-button-view-container"
                                    onClick={() => setViewActiveApplication(item, item.id)}>
                                      <button className="card-button-view"><BsEye style={{ fontSize: "20px" }}/> Monitor </button> 
                                    </li>
                                  </Link>
                                  {/* <Link to={`../update-application/${item.id}`} style={{ textDecoration: "none" }}>
                                    <li className="card-button-edit-container"
                                    onClick={() => setUpdateActiveApplication(item, item.id)}>
                                      <button className="card-button-edit"><BsPencil style={{ fontSize: "20px" }}/> Update </button>
                                    </li>
                                  </Link> */}
                                    <li className="card-button-edit-container"
                                    onClick={() => { setActiveApplicationById(item, item.id); handleShowModal() }}>
                                      <button className="card-button-edit"><BsPencil style={{ fontSize: "20px" }}/> Update </button>
                                    </li>
                                  <li className="card-button-delete-container"
                                  onClick={() => { setActiveApplicationById(item.id); deleteConfirm(item.id) }}>
                                    <button className="card-button-delete"><BsTrash style={{ fontSize: "20px" }}/> Delete </button>
                                  </li>
                                </ul>
                              </div>

                              <div className="card-body">
                                <h5 className="card-title">
                                  {(item.appName === "Ibima" || item.appName === "Bima" || item.appName === "Nanatec" || 
                                    item.appName === "Nexus" || item.appName === "Unitrust") ? 
                                  
                                    <img src={require(`../images/${item.appName}-logo.png`)} 
                                    alt="" style={{ width: "20px", height: "20px", borderRadius: "50%", marginRight: "10px" }}/>
                                    
                                  : 
                                    <Avatar name={item.appName + " " + item.appName} size="70" round={true} />
                                  } 
                                  {item.appName} <span>| {item.envName}</span>
                                </h5>

                                {/* <div className="d-flex align-items-center"> */}
                                  {/* <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                  
                                    {(item.appName === "Ibima" || item.appName === "Bima" || item.appName === "Nanatec" || 
                                      item.appName === "Nexus" || item.appName === "Unitrust") ? 
                                    
                                    <img src={require(`../images/${item.appName}-logo.png`)} 
                                      alt="" style={{ width: "100%", height: "100%" }}/>
                                      
                                      : 
                                        <Avatar name={item.appName + " " + item.appName} size="70" round={true} />
                                      }
                                  </div> */}

                                  <div style={{ display: "block", justifyContent: "space-around", textAlign: "center" }}>

                                    <span style={{ display: "block", fontSize: "11px", fontStyle: "italic", fontWeight: "light", color: "#555" }}>
                                      Status: {item.livenessStatus === "Active" && <span style={{ color: "#3B71CA" }}>{item.livenessStatus} <span className="badge rounded-pill bg-success" >Live</span></span>} 
                                      {item.livenessStatus === "Inactive" && <span style={{ color: "#3B71CA" }}>{item.livenessStatus} <span className="badge rounded-pill bg-danger">Not Live</span></span>} 
                                    </span>

                                    <span style={{ display: "block", fontSize: "11px", fontStyle: "italic", fontWeight: "light", color: "#555" }}>
                                      Runtime: <span style={{ color: "#3B71CA" }}> {item.runTime} </span></span>

                                    <span style={{ display: "block", fontSize: "11px", fontStyle: "italic", fontWeight: "light", color: "#555" }}>
                                      Memory: <span style={{ color: "#3B71CA" }}> {item.memoryUsed} / {item.memoryTotal} GB ({ item.memoryUsed / item.memoryTotal * 100 }%) </span></span>

                                    <span style={{ display: "block", fontSize: "11px", fontStyle: "italic", fontWeight: "light", color: "#555" }}>
                                      Storage: <span style={{ color: "#3B71CA" }}> {item.storageUsed} / {item.storageTotal} GB ({ item.storageUsed / item.storageTotal * 100 }%) </span></span>

                                    <span style={{ display: "block", fontSize: "11px", fontStyle: "italic", fontWeight: "light", color: "#555" }}>
                                      Api Calls: <span style={{ color: "#3B71CA" }}> {item.numberOfCalls} </span></span>  <br />

                                  </div>

                                  {/* <div style={{ display: "block", justifyContent: "space-around", textAlign: "center" }}>

                                    <span style={{ fontSize: "30px", fontStyle: "italic", fontWeight: "bold", color: "rgba(75,192,192,1)" }}>
                                      {item.runTime}</span>
                                    <span style={{ display: "block", fontSize: "15px", fontStyle: "italic", fontWeight: "light", color: "rgba(75,192,192,1)" }}>
                                      Runtime </span>

                                  </div>

                                  <div style={{ display: "flex", justifyContent: "space-around", textAlign: "center" }}>

                                  <CounterTotalMemory number={item.memoryTotal} title="Total" />
                                  <CounterUsedMemory number={item.memoryUsed} title="Used" />
                                  <CounterFreeMemory number={item.memoryTotal - item.memoryUsed} title="Free" /> 

                                  </div>

                                  <div style={{ display: "flex", justifyContent: "space-around", textAlign: "center" }}>

                                  <CounterTotalStorage number={item.storageTotal} title="Total" />
                                  <CounterUsedStorage number={item.storageUsed} title="Used" />
                                  <CounterFreeStorage number={item.storageTotal - item.storageUsed} title="Free" />

                                  </div>

                                  <div style={{ display: "flex", textAlign: "center", justifyContent: "space-around" }}>

                                  <CounterApiCalls number={item.numberOfCalls} title="Api Calls" />

                                  </div> */}


                                  <div style={{ display: "block" }}>
                                  {/* {allChartData ? <Line data={allChartData} /> : null} */}
                                  <Chart options={options} series={series} type="area" width={"100%"} height={250} />
                                  </div>

                                  {/* <div className="ps-3">
                                    <span className="small">
                                    <span className="text-muted small pt-2 ps-1"> memory </span>
                                    {item.memoryStatus === "good" && <span className="text-success small pt-1 fw-bold">{item.memoryStatus}</span>}
                                    {item.memoryStatus === "warning" && <span className="text-warning small pt-1 fw-bold">{item.memoryStatus}</span>}
                                    {item.memoryStatus === "bad" && <span className="text-danger small pt-1 fw-bold">{item.memoryStatus}</span>}
                                    <br />
                                    <span className="text-muted small pt-2 ps-1"> uptime </span>
                                    {item.upTimeStatus === "good" && <span className="text-success small pt-1 fw-bold">{item.upTimeStatus}</span>}
                                    {item.upTimeStatus === "warning" && <span className="text-warning small pt-1 fw-bold">{item.upTimeStatus}</span>}
                                    {item.upTimeStatus === "bad" && <span className="text-danger small pt-1 fw-bold">{item.upTimeStatus}</span>}
                                    <br />
                                    <span className="text-muted small pt-2 ps-1"> storage </span>
                                    {item.storageStatus === "good" && <span className="text-success small pt-1 fw-bold">{item.storageStatus}</span>}
                                    {item.storageStatus === "warning" && <span className="text-warning small pt-1 fw-bold">{item.storageStatus}</span>}
                                    {item.storageStatus === "bad" && <span className="text-danger small pt-1 fw-bold">{item.storageStatus}</span>}
                                    </span>
                                  </div> */}

                                {/* </div> */}
                              </div>

                            </div>
                          </div>)
                      ) : (
                        <div className="col-xxl-4 col-xl-12">
                          <div className="col-md-12" style={{
                            backgroundColor: "#eceff7", fontSize: "14px", width: "600px",
                            paddingTop: "10px", paddingBottom: "10px", margin: "auto", textAlign: "center"
                          }}>
                            No Apps to Display!
                          </div>
                        </div>
                      )}


                    </div>
                  </div>{/* End Left side columns */}


                </div>
              </section>

              <div>
<Modal
        show={showModal}
        onHide={() => { handleCloseModal() }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          
          <div className="modal-header-sign-up" style={{ textAlign: "center" }}>
            <h5>Update Application - {activeApplication.appName}</h5>
            <p>Fill in the details below</p>
            {message && (<div className="error">{message}</div>)}
          </div>

        </Modal.Header>
        <Modal.Body>

        <div /* className="register-body" */>
                <div className="col-md-12">
        {loading && submitted ? (<div className="info">{success}</div> 
        ) : (
                    <form className="insertForm"
                        onSubmit={handleUpdateApp} >
                        <div className="register-column">
                            <label htmlFor="appName">App Name*</label>
                            <input
                                type="text"
                                name="appName"
                                className="form-control"
                                value={activeApplication.appName}
                                onChange={handleInputChange}
                                autoComplete="off"
                                required
                            />
                            {errors.appName && <div className="form-error">{errors.appName}</div>}
                        </div>
                        <div className="register-column">
                            <label htmlFor="envName">Environment Name*</label>
                            <input
                                type="text"
                                name="envName"
                                className="form-control"
                                value={activeApplication.envName}
                                onChange={handleInputChange}
                                autoComplete="off"
                                required
                            />
                            {errors.envName && <div className="form-error">{errors.envName}</div>}
                        </div>
                        <div className="register-column">
                            <label htmlFor="description">Description*</label>
                            <input
                                type="text"
                                name="description"
                                className="form-control"
                                value={activeApplication.description}
                                onChange={handleInputChange}
                                autoComplete="off"
                                required
                            />
                            {errors.description && <div className="form-error">{errors.description}</div>}
                        </div>
                        <div className="register-column">
                            <label htmlFor="endpoint">Endpoint*</label>
                            <input
                                type="text"
                                name="endpoint"
                                className="form-control"
                                value={activeApplication.endpoint}
                                onChange={handleInputChange}
                                autoComplete="off"
                                required
                            />
                            {errors.endpoint && <div className="form-error">{errors.endpoint}</div>}
                        </div>
                        <div className="register-column">
                            <label htmlFor="storageStatus">Storage Status*</label>
                            <select
                                type="text"
                                name="storageStatus"
                                className="form-control"
                                value={activeApplication.storageStatus}
                                onChange={handleInputChange}
                                autoComplete="off"
                                issearchable="true"
                                required
                            >
                                {statusEnum.map((item) => 
                                <option key={item.label} value={item.value}>{item.label}</option>)}
                            </select>
                            {errors.storageStatus && <div className="form-error">{errors.storageStatus}</div>}
                        </div>
                        <div className="register-column">
                            <label htmlFor="upTimeStatus">Uptime Status*</label>
                            <select
                                type="text"
                                name="upTimeStatus"
                                className="form-control"
                                value={activeApplication.upTimeStatus}
                                onChange={handleInputChange}
                                autoComplete="off"
                                issearchable="true"
                                required
                            >
                                {statusEnum.map((item) => 
                                <option key={item.label} value={item.value}>{item.label}</option>)}
                            </select>
                            {errors.upTimeStatus && <div className="form-error">{errors.upTimeStatus}</div>}
                        </div>
                        <div className="register-column">
                            <label htmlFor="memoryStatus">Memory Status*</label>
                            <select
                                type="text"
                                name="memoryStatus"
                                className="form-control"
                                value={activeApplication.memoryStatus}
                                onChange={handleInputChange}
                                autoComplete="off"
                                issearchable="true"
                                required
                            >
                                {statusEnum.map((item) => 
                                <option key={item.label} value={item.value}>{item.label}</option>)}
                            </select>
                            {errors.memoryStatus && <div className="form-error">{errors.memoryStatus}</div>}
                        </div>
                        <div className="register-column">
                            <label htmlFor="notes">Notes</label>
                            <input
                                type="text"
                                name="notes"
                                className="form-control"
                                value={activeApplication.notes}
                                onChange={handleInputChange}
                                placeholder="Optional"
                                autoComplete="off"
                            />
                            {errors.notes && <div className="form-error">{errors.notes}</div>}
                        </div>
                        <div className="register-button-area">
                            <button className="register-button" type="submit" /* disabled={!isFormValid} */>
                                Update App
                            </button>
                        </div>
                    </form>
                )}
                </div>
            </div>

        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
</div>


            </main>
          </section>
        </section>

        
        <div>
          {isLoadApplication &&
            <ViewApplication list={activeApplication} />
          }
        </div>
        <div>
          {isLoadUpdateApplication &&
            <UpdateApp list={activeApplication} />}
        </div>
      </div>
      ) : (
        
        <div>
          <Login />
        </div>
        
      )}
    </div>
  );
}

export default Home;