import React, { useEffect, useState } from "react";
import ApplicationService from "../services/application-service/application-data-service";
import { useParams, useNavigate, Link } from "react-router-dom";
import Avatar from "react-avatar";
import '../App.css';
import { CounterApiCalls, CounterFreeMemory, CounterFreeStorage, CounterTotalMemory, CounterTotalStorage, 
         CounterUsedMemory, CounterUsedStorage } from "./Counter";
//import Chart from "chart.js/auto";
import Chart from "react-apexcharts";
import { Line, Doughnut, Pie } from 'react-chartjs-2';
import axios from "axios";
import { BsSpeedometer, BsGripHorizontal, BsGripVertical } from "react-icons/bs";
import { Modal, Nav, NavDropdown, Dropdown, ButtonGroup } from 'react-bootstrap';
import loadable from '@loadable/component';
import { MdOutlineQueryStats } from "react-icons/md";


const statusEnum = [
  { value: "good", label: "good" },
  { value: "warning", label: "warning" },
  { value: "bad", label: "bad" }
]

const endPoints = [
      { 
        index: 1,
        name: "Unitrust", 
        env: [
        { name: "production", link: "" },
        { name: "demo", link: "" }, 
        { name: "dev", link: "" }]
      }, 
      { 
        index: 2,
        name: "Bima", 
        env: [
        { name: "production", link: "" }, 
        { name: "demo", link: "" }, 
        { name: "dev", link: "" }]
      }, 
      { 
        index: 3,
        name: "Ibima",
        env: [
        { name: "production", link: "https://dalashabima.com:1027/api/utility/health" }, //"https://ibima.co.ke:1027/api/utility/health", 
        { name: "demo", link: "https://demo.ibima.co.ke:1027/api/utility/health" }, 
        { name: "dev", link: "" }] 
      }, 
      { 
        index: 4,
        name: "Nexus",
        env: [
        { name: "production", link: "" }, 
        { name: "demo", link: "" }, 
        { name: "dev", link: "" }] 
      }
    ];


const ViewApplication = (loggedInUser) => {

  const params = useParams();
  const navigate = useNavigate();

  const [runTimeChartData, setRunTimeChartData] = useState();
  const [memoryChartDataUsed, setMemoryChartDataUsed] = useState(1);
  const [memoryChartDataTotal, setMemoryChartDataTotal] = useState(1);
  const [memoryChartData, setMemoryChartData] = useState([35, 51, 49, 62, 69, 51, 23, 17, 29, 16, 30, 14]);
  const [storageChartDataUsed, setStorageChartDataUsed] = useState(1);
  const [storageChartDataTotal, setStorageChartDataTotal] = useState(1);
  const [storageChartData, setStorageChartData] = useState([25, 61, 29, 42, 59, 51, 53, 47, 39, 36, 53, 34]);
  const [timeData, setTimeData] = useState([25, 61, 29, 42, 59, 51, 53, 47, 39, 36, 53, 34]);
  const [combinedChartData, setCombinedChartData] = useState([]);
  const [allChartData, setAllChartData] = useState();
  const [apiChartData, setApiChartData] = useState();
  const [currentUser, setCurrentUser] = useState();
  const isApplication = useState({ id: "", appName: "", storageEndpoint: "", storageEndpointResponses: [],
    storageStatus: "", upTimeEndpoint: "", upTimeEndpointResponses: [], 
    upTimeStatus: "", memoryEndpoint: "", memoryEndpointResponses: [], memoryStatus: "",
    createdBy: "", notes: "" });
  const [data, setData] = useState([]);
  const [message, setMessage] = useState();
  const [memoryRatio, setMemoryRatio] = useState(0);
  const [storageRatio, setStorageRatio] = useState(0);
  const [apexMemoryRatio, setApexMemoryRatio] = useState(0);
  const [apexStorageRatio, setApexStorageRatio] = useState(0);
  const [activeApplication, setActiveApplication] = useState({});
  const [activeEndpoints, setActiveEndpoints] = useState();
  const [currentEnvironment, setCurrentEnvironment] = useState(endPoints[2].env[0].link);
  const [dynamicApplication, setDynamicApplication] = useState();
  const [dynamicApexApplication, setDynamicApexApplication] = useState();
  const [staticApplication, setStaticApplicationData] = useState();
  const [isLoadApplication, setLoadApplication] = useState(false);
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const [isLoadManageApp, setLoadManageApp] = useState(false);
  const toggleManageAppVisiblity = () => {
    setLoadManageApp(isLoadManageApp ? false : true);
  };


  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!activeApplication.appName) {
        newErrors.appName = "App Name is required";
        isValid = false;
    }

    if (!activeApplication.envName) {
        newErrors.storageEndpoint = "Env is required";
        isValid = false;
    }

    if (!activeApplication.description) {
      newErrors.description = "Description is required";
      isValid = false;
    }

    if (!activeApplication.endpoint) {
      newErrors.endpoint = "Endpoint is required";
      isValid = false;
    }

    if (!activeApplication.storageStatus) {
        newErrors.storageStatus = "Storage Status is required";
        isValid = false;
    }

    if (!activeApplication.upTimeStatus) {
        newErrors.upTimeStatus = "Uptime Status is required";
        isValid = false;
    }

    if (!activeApplication.memoryStatus) {
        newErrors.memoryStatus = "Memory Status is required";
        isValid = false;
      }

     if (!activeApplication.notes || activeApplication.notes) {
        isValid = true;
    }

    setErrors(newErrors);
    return isValid;
  };  


  const series = [
    {
      name: "Memory",
      data: memoryChartData,
    },
    {
      name: "Storage",
      data: storageChartData,
    }
  ];

  const options = {
    chart: {
      id: "realtime",
      height: 350,
      fontFamily: "Satoshi, sans-serif",
      type: "area",
      animations: {
        enabled: true,
        easing: "linear",
        dynamicAnimation: {
          speed: 1000 * 60
        }
      },
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    stroke: {
      width: [2, 2],
      curve: "smooth"
    },
    /* title: {
      text: activeApplication.appName,
      align: "center",
      style: {
        fontSize: "17px",
        color: "#555",
        fontWeight: "700"
      },
    }, */
    markers: {
      size: 4,
      colors: "#fff",
      strokeColors: ["#3056D3", "#80CAEE"],
      strokeWidth: 3,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      hover: {
        size: undefined,
        sizeOffset: 5,
      },
    },
    /* yaxis: {
      max: yaxisInterval
    }, */
    xaxis: {
      type: 'category',
      categories: timeData, 
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      title: {
        text: "Timeline",
        style: {
          fontSize: "15px",
          color: "#555",
          fontWeight: "600"
        },
      },
    },
    yaxis: {
      title: {
        text: "(GB)",
        style: {
          fontSize: "15px",
          color: "#555",
          fontWeight: "600"
        },
      },
    },
    legend: {
      show: true
    }
  };


  const getStaticApplication = () => {

    axios({
      method: 'get',
      url: currentEnvironment,
    })
      .then((response) => {
        const data = response.data.data;
        const filteredData = (item) => {
          const result = {
            env: item.application_info.env, runtime: item.application_info.runtime, livenessStatus: item.liveness.status, 
            host_name: item.health.host_name, server_os: item.health.server_os, api_failures: item.health.api_failures[0],
            type: item.deployment.type, provider: item.deployment.provider, 
        };
        //console.log(result);
        setStaticApplicationData(result);
        return result;
        };
        filteredData(data);
    })
    .catch((error) => {
      const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
    
        setMessage(message);
    });

  }


/*   const storageChart = () => {
    let counter = 0;
    let storData = [];
    let storageData = {
    labels: ["", "", "", "", "", ""],
    datasets: [
      {
        label: "Storage",
        data: [], //33, 25, 35, 51, 54, 76
        fill: true,
        backgroundColor: "rgba(255,127,39,0.2)",
        borderColor: "rgba(255,127,39,1)",
        lineTension: 0.8
      }
    ],
    options: {
      responsive: true,
    }
  };

  setInterval(() => {
    let chart = storageData;
    if (counter >= 36) {
      counter = 0;
      chart.datasets[0].data = new Array(counter);
    } else if (counter >= 6 && counter < 36) {
      counter++;
      let random = Math.floor(Math.random() * 10) + 1;
      chart.datasets[0].data.shift();
      chart.datasets[0].data.push(random);
      /* console.log(chart.datasets[0].data.length);
      console.log(chart.datasets[0].data); /
      setStorageChartData(chart);
    } else {
      counter++;
      let random = Math.floor(Math.random() * 10) + 1;
      chart.datasets[0].data.push(random);
      /* console.log(chart.datasets[0].data.length);
      console.log(chart.datasets[0].data); /
      setStorageChartData(chart);
    };
  }, 1000);

  }; */


  /* const curateChartData = () => {

    let chartData = {
      labels: ["", "", "", "", "", "", "", "", "", "", "", ""],
      datasets: [
        {
          label: "Used Storage",
          data: [33, 53, 85, 41, 44, 65, 33, 53, 85, 41, 44, 65], //33, 53, 85, 41, 44, 65
          fill: false,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "#80CAEE", //"rgba(75,192,192,1)",
          lineTension: 0.4
        },
        {
          label: "Used Memory",
          data: [33, 25, 35, 51, 54, 76, 33, 25, 35, 51, 54, 76], //33, 25, 35, 51, 54, 76
          fill: false,
          borderColor: "#3C50E0",  //"#742774",
          lineTension: 0.4
        }
      ],
      options: {
        /* responsive: true, */
        /* interaction: {
          mode: 'index',
          intersect: false,
        },
        stacked: false, /
        plugins: {
          title: {
            display: true,
            text: 'Chart.js Line Chart - Multi Axis'
          }
        },
        scales: {
          x: {
            display: true,
            title: {
              display: true,
              text: 'Time'
            }
          },
          y: {
            display: true,
            title: {
              display: true,
              text: '(GB)'
            }
          }
        }
      }
    };

  setInterval(() => { 

    let chart = chartData;

    axios({
      method: 'get',
      url: currentEnvironment //endPoints[2].env[0].link,
    })
      .then((response) => {
        const result = response.data.data;
        setDynamicApplication(result);

        if (chart.datasets[0].data.length >= 14) {

        let usedMemoryData = Math.round(result.health.memory.used / 1000000);
        let usedStorageData = Math.round(result.health.storage.used / 1000000000);
        let totalMemoryData = Math.round(result.health.memory.total / 1000000);
        let totalStorageData = Math.round(result.health.storage.total / 1000000000);
        let memoryratio = Math.trunc(usedMemoryData / totalMemoryData * 100);
        let storageratio = Math.trunc(usedStorageData / totalStorageData * 100);
        chart.datasets[1].data.shift();
        chart.datasets[0].data.shift();
        chart.datasets[1].data.push(usedMemoryData);
        chart.datasets[0].data.push(usedStorageData);
        /* setStorageChartData(storageChart);
        setMemoryChartData(memoryChart); /
        setAllChartData(chart);
        setStorageChartDataUsed(usedStorageData);
        setStorageChartDataTotal(totalStorageData);
        setMemoryChartDataUsed(usedMemoryData);
        setMemoryChartDataTotal(totalMemoryData);
        setStorageRatio(storageratio);
        setMemoryRatio(memoryratio);
        //return usedMemoryData;
        return {usedMemoryData, totalMemoryData, usedStorageData, totalStorageData, memoryratio, storageratio};

        } else { 

          let usedMemoryData = Math.round(result.health.memory.used / 1000000);
          let usedStorageData = Math.round(result.health.storage.used / 1000000000);
          let totalMemoryData = Math.round(result.health.memory.total / 1000000);
          let totalStorageData = Math.round(result.health.storage.total / 1000000000);
          let memoryratio = Math.trunc(usedMemoryData / totalMemoryData * 100);
          let storageratio = Math.trunc(usedStorageData / totalStorageData * 100);
          chart.datasets[1].data.push(usedMemoryData);
          chart.datasets[0].data.push(usedStorageData);
          /* setStorageChartData(storageChart);
          setMemoryChartData(memoryChart); /
          setAllChartData(chart);
          setStorageChartDataUsed(usedStorageData);
          setStorageChartDataTotal(totalStorageData);
          setMemoryChartDataUsed(usedMemoryData);
          setMemoryChartDataTotal(totalMemoryData);
          setStorageRatio(storageratio);
          setMemoryRatio(memoryratio);
          //return usedMemoryData;
          return {usedMemoryData, totalMemoryData, usedStorageData, totalStorageData, memoryratio, storageratio};

        };
    })
    .catch((error) => {
      const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
    
        setMessage(message);
    });
  }, 1000 * 60 * 5);

  }; */

  

  useEffect(() => {

    const userData = loggedInUser.loggedInUser;
    const appData = loggedInUser.data;

    setCurrentUser(userData);
    setData(appData);
    
    
    getStaticApplication();


    ApplicationService.viewApplicationById(params.id).then(
      (response) => { 
        const data = response.data;
        const filteredData = (item) => {
          const result = {
            id: item._id, appName: item.appName, version: item.application.version, appEnv: item.application.appEnv, runTime: item.application.runTime, 
            livenessStatus: item.liveness.status, memoryTotal: item.health.memory.total, memoryUsed: item.health.memory.used, memoryStatus1: item.health.memory.status, 
            storageTotal: item.health.storage.total, storageUsed: item.health.storage.used, storageStatus1: item.health.storage.status, serverType: item.health.serverType, 
            serverOS: item.health.serverOS, numberOfCalls: item.health.api.numberOfCalls, deploymentType: item.deployment.type, deploymentProvider: item.deployment.provider, 
            envName: item.envName, description: item.description, queryDate: item.queryDate, storageStatus: item.storageStatus, upTimeStatus: item.upTimeStatus, 
            memoryStatus: item.memoryStatus, createdBy: item.createdBy, notes: item.notes, createdAt: item.createdAt
        };
        const filteredEndPoints = endPoints.filter((item) => {
          return item.name === result.appName;
        });
        const filteredEndPointLinks = filteredEndPoints[0].env.filter((item) => {
          return item.link.toString().length >= 1;
        });
        setActiveEndpoints(filteredEndPointLinks);
        setActiveApplication(result);
        const memoryUsed = result.memoryUsed;
        const memoryTotal = result.memoryTotal;
        const storageUsed = result.storageUsed;
        const storageTotal = result.storageTotal;
        setMemoryRatio(memoryUsed / memoryTotal);
        setStorageRatio(storageUsed / storageTotal);
        return result;
        };
        filteredData(data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
    
        setMessage(message)
      }
    ); 

    const interval = setInterval(() => {

      axios({
          method: 'get',
          url: currentEnvironment //"https://dalashabima.com:1027/api/utility/health" //endPoints[2].env[0].link,
        })
          .then((response) => {
            const result = response.data.data;
            let usedMemoryData = Math.round(result.health.memory.used / 1000000);
            let usedStorageData = Math.round(result.health.storage.used / 1000000000);
            let totalMemoryData = Math.round(result.health.memory.total / 1000000);
            let totalStorageData = Math.round(result.health.storage.total / 1000000000);
            let newTime = new Date().toLocaleTimeString();
            let memoryArray = [...memoryChartData, usedMemoryData];
            let storageArray = [...storageChartData, usedStorageData];
            let timeArray = [...timeData, newTime];
            let memoryratio = Math.trunc(usedMemoryData / totalMemoryData * 100);
            let storageratio = Math.trunc(usedStorageData / totalStorageData * 100);
            memoryArray.shift();
            storageArray.shift();
            timeArray.shift();
            setMemoryChartData(memoryArray);
            setStorageChartData(storageArray);
            setTimeData(timeArray);
            setMemoryChartDataUsed(usedMemoryData);
            setStorageChartDataUsed(usedStorageData);
            setMemoryChartDataTotal(totalMemoryData);
            setStorageChartDataTotal(totalStorageData);
            setStorageRatio(storageratio);
            setMemoryRatio(memoryratio);
            return;
        })
        .catch((error) => {
          const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
        
            setMessage(message);
        });

    }, 1000 * 60);
    return () => {
      window.clearInterval(interval); // clear the interval in the cleanup function
    };


  }, [params, setActiveApplication, setCurrentUser, loggedInUser, setData, currentEnvironment, memoryChartData, storageChartData, timeData]);



  const setViewActiveApplication = (application) => {
    setActiveApplication(application);
    setLoadApplication(true);
  };


    const handleEnvironmentChange = (e) => {

      setTimeout(() => {
        setStaticApplicationData();
        //setMemoryChartData([]);
        //setStorageChartData([]);
        //setTimeData([]);
      }, 1300);

      setTimeout(() => {
        setCurrentEnvironment(e.target.value);
      }, 2300);

      setTimeout(() => {
        getStaticApplication();
        //curateChartData();
        //setInterval(curateChartData, 1000 * 60 * 5);
      }, 3300);

      };



  return (
    <div>
    {currentUser && activeApplication /* && staticApplication */ ? (<div className="App">
      
  
    {/* <body> */}
      <link rel="stylesheet" href={require("../assets/css/style.css")}></link>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.min.css"></link>
      <link rel="stylesheet" href={require("../assets/css/bootstrap.min.css")}></link>
      <link rel="stylesheet" href={require("../assets/css/bootstrap-reboot.min.css")}></link>

      {/* <script
      src="https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.9.4/Chart.js">
      </script> */}
      
      
      
      <section className="container-fluid">
        <section className="row">
          <aside className="sidebar sidebar-nav border border-right col-md-3 col-lg-2">
            <div className="offcanvas-md offcanvas-end bg-body-tertiary" tabIndex="-1" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="sidebarMenuLabel">Company name</h5>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu" aria-label="Close"></button>
              </div>
              <div className="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto">
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/home">
                    <i className="bi-house-fill"></i>
                    Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="/applications">
                      <i className="bi bi-grid"></i><span>Applications</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    {data && data.length >= 1 ? (
                      data.map((item, key) =>
                    <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav" key={key}>
                      <Link to={`../view-application/${item.id}`} key={key}>
                      <li className="nav-item" key={key} onClick={ () => setViewActiveApplication(item, item.id) }>
                        {(item.appName === "Ibima" || item.appName === "Bima" || item.appName === "Nanatec" || 
                            item.appName === "Nexus" || item.appName === "Unitrust") ? 
                            
                          <img src={require(`../images/${item.appName}-logo.png`)} 
                          alt="" style={{ width: "20px", height: "20px", borderRadius: "50%" }}/>
                          
                          : 
                          <Avatar name={item.appName + " " + item.appName} size="20" round={true} />
                        }
                        <span> {item.appName}</span>
                      </li>
                      </Link>
                    </ul>)
                  ) : (
                    <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                      <li className="nav-item">
                        <span>No Apps Found!</span>
                      </li>
                    </ul>
                  )}
                    </li>
                    <li className="nav-item">
                    <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/users">
                    <i className="bi-people"></i>
                    Users
                    </a>
                  </li>
                  </ul>
              </div>
            </div>
          </aside>
        
          <main className="dashboard col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <div className="pagetitle">
          {(activeApplication.appName === "Ibima" || activeApplication.appName === "Bima" || activeApplication.appName === "Nanatec" || 
              activeApplication.appName === "Nexus" || activeApplication.appName === "Unitrust") ? 
              
            <img src={require(`../images/${activeApplication.appName}-logo.png`)} 
            alt="" style={{ width: "35px", height: "35px", borderRadius: "50%", marginBottom: "10px" }}/>
            
            : 
            <Avatar name={activeApplication.appName + " " + activeApplication.appName} size="35" round={true} />
          }
          <h1> {activeApplication.appName}</h1>
          <nav className="breadcrumbs">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a className="breadcrumb-menu-a" href="/home">Home</a></li>
            <li className="breadcrumb-item"><a className="breadcrumb-menu-a" href="/applications">Applications</a></li>
            <li className="breadcrumb-item"><span style={{ backgroundColor: "#555", color: "white", borderRadius: "5px", padding: "5px" }}>{activeApplication.appName}</span></li>
          </ol>
          </nav>
        </div>
        <section className="section">
          
        </section>
      
        <section className="section dashboard">
          <div className="row">

            <div className="col-lg-12">

              {/* <div className="card">
              <div className="card-body">
                <h5 className="card-title">Environments</h5>

                <table className="table">
                <thead>
                  <tr>
                  <th scope="col text-nowrap" style={{ width: "100px" }}>Env. Name</th>
                  <th scope="col">Description</th>
                  <th scope="col">Endpoint</th>
                  <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="small">
                  <td>{activeApplication.appEnv}</td>
                  <td>{activeApplication.description}</td>
                  <td>{activeApplication.endpoint}</td>
                  <td style={{ position: "relative", display: "block" }}>
                    <div style={{ position: "relative", display: "inline-block" }} onClick={toggleManageAppVisiblity}>
                      {isLoadManageApp ? <span className="text-warning"><i className="bi bi-eye-slash-fill text-nowrap"> </i></span> : <span className="text-info"><i className="bi bi-eye-fill text-nowrap"> </i></span>}
                    </div>
                    {isLoadManageApp ? <div style={{ position: "relative", display: "inline-block" }}>
                    <button type="button" className="btn btn-primary btn-sm" style={{ marginLeft: "5px", marginRight: "5px" }}>
                    <i className="bi bi-eye text-nowrap"> </i> </button>
                    <button type="button" className="btn btn-success btn-sm" onClick={() =>  handleShowModal()} style={{ marginLeft: "5px", marginRight: "5px" }}>
                    <i className="bi bi-pencil text-nowrap"> </i> </button>
                    <button type="button" className="btn btn-danger btn-sm" onClick={() => deleteConfirm(activeApplication.id)} style={{ marginLeft: "5px", marginRight: "5px" }}>
                    <i className="bi bi-trash text-nowrap"> </i> </button>
                    </div> : null}
                  </td>
                  </tr>
                  {/* <tr>
                  <td>Prod</td>
                  <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</td>
                  <td>prod.nexus.ke</td>
                  <td><button type="button" className="btn btn-primary btn-sm">
                    <i className="bi bi-eye text-nowrap"> View</i></button>
                  </td>
                  </tr> /}
                </tbody>
                </table>
              </div>
              </div> */}


              <div className="col-xxl-4 col-xl-12">
                {/* <h5 className="card-title"><BsSpeedometer /> Performance Metrics</h5> */}
                <div className="row">

                  {/* <div className="col-xxl-4 col-md-6">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      <h5 className="card-title">Runtime</h5>
                      <div style={{ display: "block", justifyContent: "space-around", textAlign: "center" }}>
                        <span style={{ fontSize: "20px", fontStyle: "italic", fontWeight: "bold", color: "rgba(75,192,192,1)" }}>
                           {activeApplication.runTime}</span>
                        <span style={{ display: "block", fontSize: "15px", fontStyle: "italic", fontWeight: "light", color: "rgba(75,192,192,1)" }}> Runtime </span>
                      </div>
                      {runTimeChartData ? <Line data={runTimeChartData} /> : null}
                    </div>
                  </div>
                  </div> 

                  <div className="col-xxl-4 col-md-6">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      <h5 className="card-title">Memory</h5>
                      <div style={{ display: "flex", justifyContent: "space-around", textAlign: "center" }}>
                        <CounterTotalMemory number={activeApplication.memoryTotal} title="Total" />
                        <CounterUsedMemory number={activeApplication.memoryUsed} title="Used" />
                        <CounterFreeMemory number={activeApplication.memoryTotal - activeApplication.memoryUsed} title="Free" />
                      </div>
                      {memoryChartData ? <Line data={memoryChartData} /> : null}
                    </div>
                  </div>
                  </div>

                  <div className="col-xxl-4 col-md-6">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      <h5 className="card-title">Storage</h5>
                      <div style={{ display: "flex", justifyContent: "space-around", textAlign: "center" }}>
                        <CounterTotalStorage number={activeApplication.storageTotal} title="Total" />
                        <CounterUsedStorage number={activeApplication.storageUsed} title="Used" />
                        <CounterFreeStorage number={activeApplication.storageTotal - activeApplication.storageUsed} title="Free" />
                      </div>
                      <div style={{ display: "flex", position: "relative", justifyContent: "space-around", /* height: "154px", width: "308px", margin: "0 auto", padding: "0"  }} >
                       /* <Doughnut data={storageDoughnutData} options={storageDoughnutOptions} /> 
                        {storageChartData ? <Line data={storageChartData} /> : null}
                      </div>
                    </div>
                  </div>
                  </div>

                  <div className="col-xxl-4 col-md-6">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      <h5 className="card-title">Api Calls</h5>
                      <div style={{ display: "flex", textAlign: "center", justifyContent: "space-around" }}>
                      <CounterApiCalls number={activeApplication.numberOfCalls} title="Api Calls" />
                      </div>
                      <div style={{ display: "flex", justifyContent: "space-around" }}>
                        {apiChartData ? <Line data={apiChartData} /> : null}
                      </div>
                    </div>
                  </div>
                  </div> */}

                  <div className="col-xxl-4 col-md-12">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      {/* <h5 className="card-title">Api Calls</h5> */}
                      <div className="row align-items-start">

                          <div className="col">
                          <h4 className="card-title" style={{ marginLeft: "20px", fontSize: "20px", fontStyle: "normal", fontWeight: "bold", 
                            color: "#555" }}>
                            {activeApplication.appName} V{activeApplication.version} {staticApplication ? <span> | {staticApplication?.env}</span> : null}
                          </h4>
                          <p className="card-subtitle" style={{ marginLeft: "20px", fontSize: "15px", fontStyle: "normal", fontWeight: "light", 
                            color: "#555" }}> 
                            <MdOutlineQueryStats style={{ marginRight: "5px" }} /> Performance Metrics
                          </p>
                          </div>

                          <div className="col">
                          <p className="card-subtitle" style={{ fontSize: "15px", fontStyle: "normal", fontWeight: "light", color: "#555",
                             textAlign: "center", marginTop: "5px" }}>
                            Status: {staticApplication ? <span> 
                            {staticApplication?.livenessStatus === "RUNNING" ? 
                            <span className="badge rounded-pill bg-success"> {staticApplication?.livenessStatus}</span>
                            :
                            <span className="badge rounded-pill bg-danger"> {staticApplication?.livenessStatus}</span>}
                          </span> : null}
                          </p>
                          <p className="card-subtitle" style={{ fontSize: "15px", fontStyle: "normal", fontWeight: "light", color: "#555",
                             textAlign: "center", marginTop: "5px" }}>
                              Environment: {staticApplication ? <strong>{staticApplication?.env}</strong> : null}
                             </p>
                          </div>

                          <div className="col">
                          <select
                            name="env"
                            id="env"
                            placeholder="Select Environment"
                            className="form-select form-select-sm mb-3"
                            style={{ marginRight: "20px" }}
                            onChange={handleEnvironmentChange}
                            >
                              <option key="" value="">Select Environment</option>
                              {activeEndpoints && activeEndpoints.map((item) => 
                                <option key={item.name} value={item.link}>{item.name}</option>)}
                              {/* <option>Production</option>
                              <option>Demo</option>
                              <option>Development</option> */}
                            </select>
                          </div>

                        </div>

                        {message ? <div className="error" style={{ marginTop: "40px" }}>
                          {message}
                          <span style={{ float: "right" }}>
                          <i className="bi bi-x-circle" onClick={() => setMessage(null)}></i>
                          </span>
                          </div> 

                        :

                        <div>
                      <div className="col-md-12" /* style={{ display: "flex", justifyContent: "space-around", height: "400px" }} */>
                      <div style={{ display: "block", justifyContent: "space-around", marginTop: "10px", marginBottom: "10px" }}>
                        <Chart options={options} series={series} type="area" width={"100%"} height={350} />
                      </div>
                      </div>

                      <div className="col-12">

                      <div className="row align-items-start p-2 rounded-3">

                      <div className="col bg-light border rounded-3">
                      <p className="card-title" style={{ fontSize: "16px", fontStyle: "normal", fontWeight: "bold", color: "#555", 
                        textAlign: "center", marginTop: "10px" }}>
                        App Details
                      </p>
                      </div>

                      </div>
                      
                      <div className="row align-items-start p-2 rounded-3">

                      <div className="col-md-4 bg-light border rounded-3">
                      <p className="card-subtitle" style={{ fontSize: "15px", fontStyle: "normal", fontWeight: "bold", color: "#555", 
                        textAlign: "center", marginBottom: "10px", marginTop: "10px" }}>
                        General
                      </p>
                      <ul className="list-unstyled" style={{ fontSize: "14px", fontStyle: "normal", 
                        fontWeight: "normal", color: "#555" }}>
                        <li>Application Name: <span className="text-primary">{activeApplication?.appName}</span></li>
                        <li>Description: <span className="text-primary">{activeApplication?.appName} Monitoring</span></li>
                        <li>Environment: {staticApplication ? <span className="text-primary">{staticApplication?.env}</span> : null}</li>
                        {staticApplication && staticApplication.livenessStatus === "RUNNING" ? 
                        <li>Status: <span className="badge rounded-pill bg-success">{staticApplication?.livenessStatus}</span></li>
                        :
                        <li>Status: <span className="badge rounded-pill bg-danger">{staticApplication?.livenessStatus}</span></li>}
                      </ul>
                      </div>

                      <div className="col-md-4 bg-light border rounded-3">
                      <p className="card-subtitle" style={{ fontSize: "15px", fontStyle: "normal", fontWeight: "bold", color: "#555", 
                        textAlign: "center", marginBottom: "10px", marginTop: "10px" }}>
                        Health
                      </p>
                      <ul className="list-unstyled" style={{ fontSize: "14px", fontStyle: "normal", 
                        fontWeight: "normal", color: "#555" }}>
                        <li>Run Time: {staticApplication ? <span className="text-primary">{staticApplication?.runtime}</span> : null}</li>
                        <li>Memory: <span className="text-primary">{memoryChartDataUsed} | {memoryChartDataTotal} GB ({memoryRatio}%)</span></li>
                        <li>Storage: <span className="text-primary">{storageChartDataUsed} | {storageChartDataTotal}  GB ({storageRatio}%)</span></li>
                        <li>Api Failures: 
                          {staticApplication ? <span className="badge rounded-pill bg-danger">{staticApplication?.api_failures}</span> 
                          :
                          <span className="badge rounded-pill bg-success">No Api Failures!</span>}
                        </li>
                      </ul>
                      </div>

                      <div className="col-md-4 bg-light border rounded-3">
                      <p className="card-subtitle" style={{ fontSize: "15px", fontStyle: "normal", fontWeight: "bold", color: "#555", 
                        textAlign: "center", marginBottom: "10px", marginTop: "10px" }}>
                        Deployment
                      </p>
                      <ul className="list-unstyled" style={{ fontSize: "14px", fontStyle: "normal", 
                        fontWeight: "normal", color: "#555" }}>
                        <li>Deployment Type: {staticApplication ? <span className="text-primary">{staticApplication?.type}</span> : null}</li>
                        <li>Provider: {staticApplication ? <span className="text-primary">{staticApplication?.provider}</span> : null}</li>
                        <li>Host Name: {staticApplication ? <span className="text-primary">{staticApplication?.host_name}</span> : null}</li>
                        <li>Server OS: {staticApplication ? <span className="text-primary">{staticApplication?.server_os}</span> : null }</li>
                      </ul>
                      </div>

                      </div>
                      </div>
                      </div>
                      }

                    </div>
                  </div>
                  </div>

                </div>
              </div>


            </div>


            {/* <div className="col-lg-4">

              <div className="card info-card customers-card">
              <div className="card-body">
                <h5 className="card-title">App Performance</h5>
                {/* <Line data={chartData} /> /}
                <table className="table table-borderless">
                  
                  <tbody className="small">
                    <tr>
                    <th style={{ color: "#555" }}>Memory</th>
                    {memoryRatio <= 0.6 ? 
                    <td className="text-success">{memoryRatio * 100}% 
                    </td> : null}
                    {(memoryRatio > 0.6 && memoryRatio <= 0.8) ? 
                    <td className="text-warning">{memoryRatio * 100}% 
                    </td> : null}
                    {memoryRatio > 0.8 ? 
                    <td className="text-danger">{memoryRatio * 100}% 
                    </td> : null}
                    </tr>
                    <tr>
                    <th style={{ color: "#555" }}>Storage</th>
                    {storageRatio <= 0.6 && <td className="text-success">{storageRatio * 100}%</td>}
                    {(storageRatio > 0.6 && storageRatio <= 0.8) && <td className="text-warning">{storageRatio * 100}%</td>}
                    {storageRatio > 0.8 && <td className="text-danger">{storageRatio * 100}%</td>}
                    </tr>
                    <tr>
                    <th style={{ color: "#555" }}>Uptime</th>
                    <td style={{ color: "#3751FF" }}>{activeApplication.runTime}</td>
                    </tr>
                    <tr>
                    <th style={{ color: "#555" }}>Api Calls</th>
                    <td style={{ color: "#3751FF" }}>{activeApplication.numberOfCalls}</td>
                    </tr>
                  </tbody>
                  </table>
              </div>
              </div>
              
              <div className="card">
              <div className="card-body">
                <h5 className="card-title">App Details</h5>
                <div className="d-flex align-items-top">
                  <div className="ps-3">
                    <div className="small">
                    <b className="rounded-circle d-flex align-items-top" style={{ color: "#555" }}>General</b>
                      <li className="small"><strong style={{ color: "#555" }}>Status: </strong><span> 
                        {activeApplication.livenessStatus === "Active" && <span className="badge rounded-pill bg-success"> {activeApplication.livenessStatus}</span> }
                        {activeApplication.livenessStatus === "Inactive" && <span className="badge rounded-pill bg-danger"> {activeApplication.livenessStatus}</span> }
                        </span></li>
                      <li className="small"><strong style={{ color: "#555" }}>Environment: </strong><span> 
                        {activeApplication.appEnv === "prod" && <span className="text-success"> {activeApplication.appEnv}</span>}
                        {activeApplication.appEnv === "demo" && <span className="text-primary"> {activeApplication.appEnv}</span>}
                        {activeApplication.appEnv === "dev" && <span className="text-info"> {activeApplication.appEnv}</span>}
                        </span></li>
                      <li className="small"><strong style={{ color: "#555" }}>Version:  
                        </strong><span style={{ color: "#3751FF" }}> {activeApplication.version}</span></li>
                      <li className="small"><strong style={{ color: "#555" }}>Run Time: 
                        </strong><span style={{ color: "#3751FF" }}> {activeApplication.runTime}</span></li>
                      <li className="small"><strong style={{ color: "#555" }}>Server Type: 
                        </strong><span style={{ color: "#3751FF" }}> {activeApplication.serverType}</span></li>
                      <li className="small"><strong style={{ color: "#555" }}>Server OS: 
                        </strong><span style={{ color: "#3751FF" }}> {activeApplication.serverOS}</span></li>
                      <br />
                    <b className="rounded-circle d-flex align-items-top" style={{ color: "#555" }}>Health</b>
                      <li className="small"><strong style={{ color: "#555" }}>Memory: 
                        </strong><span style={{ color: "#3751FF" }}> {activeApplication.memoryUsed} / {activeApplication.memoryTotal}</span></li>
                      <li className="small"><strong style={{ color: "#555" }}>Storage: 
                        </strong><span style={{ color: "#3751FF" }}> {activeApplication.storageUsed} / {activeApplication.storageTotal}</span></li>
                      <li className="small"><strong style={{ color: "#555" }}>Api Calls: 
                        </strong><span style={{ color: "#3751FF" }}> {activeApplication.numberOfCalls}</span></li>
                      <br />
                    <b className="rounded-circle d-flex align-items-top" style={{ color: "#555" }}>Deployment</b>
                      <li className="small"><strong style={{ color: "#555" }}>Type: </strong><span style={{ color: "#3751FF" }}> {activeApplication.deploymentType}</span></li>
                      <li className="small"><strong style={{ color: "#555" }}>Provider: </strong><span style={{ color: "#3751FF" }}> {activeApplication.deploymentProvider}</span></li>
                    </div> 
                  </div>
                </div>
              </div>
              </div>

              {/* <div className="card">
              <div className="card-body">
                <div className="card-title d-flex align-items-center"><b className="cfard-title p-r4" style={{ color: "#555" }}>Stats</b></div>
                
                  
              </div>
              </div> /}

            </div> */}


            </div>
          </section>

  



        </main>
        
        </section> 
      </section> 
      
 </div> 
    ) : (
      null
    )}
    </div>
  );
}

export default ViewApplication;