import React, { useState, useEffect } from "react";
import './App.css';
import { BrowserRouter, Routes, Route, } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js"
import Register from './components/Register';
import Login from './components/Login';
import FirstTimeLogin from './components/FirstTimeLogin';
import AuthService from './services/user-service/auth-service';
import UserService from "./services/user-service/user-data-service";
import ApplicationService from "./services/application-service/application-data-service";
import OTPRecovery from "./components/OTPRecovery";
import RecoverPassword from "./components/RecoverPassword";
import ForgotPassword from "./components/ForgotPassword";
import Profile from "./components/Profile";
import Users from "./components/Users";
import Applications from "./components/Applications";
import UpdateApp from "./components/UpdateApp";
import ViewApplication from "./components/View-Application";
import Home from "./components/Home";
import ViewUser from "./components/View-User";
import { BsBell, BsPower } from "react-icons/bs";
import TestRegister from "./components/RegisterTest";
import Graph from "./components/Graph";
import axios from "axios";


const App = () => {

  const isUser = useState({ _id: "", firstName: "", lastName: "", username: "",
    phoneNumber: "", email: "", role: "", status: "", });
  const [currentUser, setCurrentUser] = useState();
  const [message, setMessage] = useState();
  const [data, setData] = useState();
  const [ibimaData, setIbimaData] = useState();
  const [logOutMessage, setLogOutMessage] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [viewProfilePicture, setViewProfilePicture] = useState();

  const ibimaEndpoint = async () => {

    let allMemoryData = [];
    let allStorageData = [];

    /* setInterval(() => {

      let allMemoryDataUsed = allMemoryData;
      let allStorageDataUsed = allStorageData;

      axios({
        method: 'get',
        url: "https://demo.ibima.co.ke:1027/api/utility/health",
      })
        .then((response) => {
          const result = response.data.data;
          //console.log(result);
          let memoryData = result.health.memory.used;
          let storageData = result.health.storage.used;
          allMemoryDataUsed.push(memoryData);
          allStorageDataUsed.push(storageData);
          //console.log(allMemoryDataUsed);
          //console.log(allStorageDataUsed);
          setIbimaData(result);
      })
      .catch((error) => {
        const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
      
          setMessage(message);
      });

    }, 10000); */
    
  };


  useEffect(() => {
    window.scrollTo(0, 0);

    //const user = AuthService.getCurrentUser();
    //setCurrentUser(user); 
    /* const token = user.tokens[user.tokens.length - 1].token;
    const parsed = JSON.parse(atob(token.split(".")[1]));
    if (parsed.exp * 1000 < Date.now()) {
      console.log("how the?")
      return setCurrentUser();
    } else {
      return setCurrentUser(user);
    } */
    /* if (user) {
      return setCurrentUser(user);
    }; */


    ibimaEndpoint();

    UserService.viewMyProfile().then(
      (response) => {
        const data = response.data; 
        const user = AuthService.getCurrentUser();
        if (data && user && (data._id === user._id)) {
          setCurrentUser(data);
          if (data && data.profilePicture) {
            setViewProfilePicture(data.profilePicture);
            return;
          };
          return;
        };
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
    
        setMessage(message);
        if (message === "Request failed with status code 401") {
          setLoading(true);
          setLogOutMessage("Session Expired! Login...");
          setTimeout(() => {
            setLoading(false);
            setCurrentUser(null);
            localStorage.removeItem("user"); 
            /* logOut(); */
          }, 5000);
          return;
        };
      }
    );

    
    ApplicationService.allApplications().then(
      (response) => {
        const data = response.data.reverse();
        const postData = data.map(item => ({
          id: item._id, appName: item.appName, version: item.application.version, appEnv: item.application.appEnv, runTime: item.application.runTime, 
          livenessStatus: item.liveness.status, memoryTotal: item.health.memory.total, memoryUsed: item.health.memory.used, memoryStatus1: item.health.memory.status, 
          storageTotal: item.health.storage.total, storageUsed: item.health.storage.used, storageStatus1: item.health.storage.status, serverType: item.health.serverType, 
          serverOS: item.health.serverOS, numberOfCalls: item.health.api.numberOfCalls, deploymentType: item.deployment.type, deploymentProvider: item.deployment.provider, 
          envName: item.envName, description: item.description, queryDate: item.queryDate, storageStatus: item.storageStatus, upTimeStatus: item.upTimeStatus, 
          memoryStatus: item.memoryStatus, createdBy: item.createdBy, notes: item.notes, createdAt: item.createdAt
        }))
        setData(postData);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setMessage(message)
      }
    );


    
    /* if (message === "Request failed with status code 401") {
      setLoading(true);
      setLogOutMessage("Session Expired! Please Login to Continue...");
      setTimeout(() => {
        setLogOutMessage();
        setLoading(false);
        logOut();
      }, 4000);
      return;
    }; */


    
  }, [setCurrentUser, setViewProfilePicture, message, setData]);


  const logOut = async () => {
    
    UserService.logOut().then(
      (response) => {
        window.location.replace("/");
        localStorage.removeItem("user"); 
        return response.data;
      },
      (error) => {
          const message =
              (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
              error.message ||
              error.toString();

          setMessage(message);
      }
  );
  }; 



  return (
    <div className="App">
      {currentUser && data ? (
        <BrowserRouter>
          <div>
      {/* <script src="./assets/js/bootstrap.bundle.min.js"></script> */}
      <link rel="stylesheet" href={require("./assets/css/style.css")}></link>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.min.css"></link>
      <link rel="stylesheet" href={require("./assets/css/bootstrap.min.css")}></link>
      <link rel="stylesheet" href={require("./assets/css/bootstrap-reboot.min.css")}></link>

      <header className="navbar sticky-top bg-dark flex-md-nowrap p-0 shadow" data-bs-theme="dark">

      <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6 text-white" href="/home">
      <img src={require(`./images/Nanatec-logo.png`)} alt=""
      style={{ width: "30px", height: "30px", marginRight: "10px" }}>
      </img>
        Nanatec Monitoring
        </a>
      
      <div className="nav-bar-logout">
                <button className="logout-button" onClick={logOut}><BsPower style={{fontSize: "20px"}} /> LOGOUT</button>
                <a className="nav-bar-name" href="/profile"
                ><div style={{ display: "inline-block", paddingBottom: "0px", paddingRight: "5px", paddingLeft: "5px", marginTop: "0px", paddingTop: "0px" }}>
                  <p style={{ display: "inline-block", fontSize: "14px", paddingBottom: "0px", marginBottom: "0px" }}>{currentUser.firstName + " " + currentUser.lastName}</p>
                  <p style={{ fontSize: "11px", paddingTop: "0px", marginTop: "0px", paddingBottom: "0px", marginBottom: "0px" }}>{currentUser.role} </p>
                 </div>
                  {viewProfilePicture ? ( <div className="navbar-picture-container">
                    <img
                    src={require(`./images/profile-pictures/${viewProfilePicture}`)}
                    alt="Selected"
                    style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                    />
                  </div>
                  ) : (
                    <div className="navbar-picture-container">
                      <img src="https://bootdey.com/img/Content/avatar/avatar2.png" 
                      alt="" 
                      style={{ width: "100%", height: "100%", borderRadius: "50%" }}/>
                    </div>
                  )}
                </a>
                <a href="/notifications" className="nav-bar-notification"><BsBell style={{display: "inline-block", position:"relative"}}/> |</a> 
              </div>
        
  
        <ul className="navbar-nav flex-row d-md-none">
        <li className="nav-item text-nowrap">
          on
          <button className="nav-link px-3 text-white" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSearch" aria-controls="navbarSearch" aria-expanded="false" aria-label="Toggle search">
          <i className="bi-search">on</i>on
          </button>
        </li>
        <li className="nav-item text-nowrap">
          off
          <button className="nav-link px-3 text-white" type="button" data-bs-toggle="offcanvas" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
          <i className="bi-list">off</i>off
          </button>
        </li>
        </ul>
      </header>
          </div>
          <Routes>
            {/* <Route index element={<Login />} />  */}
            <Route path="home" element={<Home loggedInUser={currentUser} data={data}/>} />
            <Route path="register" element={<Register loggedInUser={currentUser} data={data}/>} />
            <Route path="test-register" element={<TestRegister loggedInUser={currentUser} data={data}/>} />
            <Route path="first-time-login" element={<FirstTimeLogin />} />
            <Route path="otp-recovery" element={<OTPRecovery />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="recover-password" element={<RecoverPassword />} />
            <Route path="profile" element={<Profile loggedInUser={currentUser} data={data}/>} />
            <Route path="applications" element={<Applications loggedInUser={currentUser} data={data}/>} />
            <Route path="view-application/:id" element={<ViewApplication loggedInUser={currentUser} data={data}/>} />
            <Route path="update-application/:id" element={<UpdateApp loggedInUser={currentUser} data={data}/>} />
            <Route path="users" element={<Users loggedInUser={currentUser} data={data}/>} />
            <Route path="view-user/:id" element={<ViewUser loggedInUser={currentUser} data={data}/>} />
            <Route path="graph" element={<Graph loggedInUser={currentUser} data={data}/>} />
          </Routes>
          {/* </div>
          </div> 
                <div style={{paddingTop: "50px", marginLeft: "25%", width: "60%"}}>
                <div className="config-list-group-table">
                  {isLoading && logOutMessage && <div className="warning">{logOutMessage}</div>}
                  </div>
                </div> */}
        </BrowserRouter>
      ) : (
        <BrowserRouter>
        <div>
          {logOutMessage && <Login loggedOutUser={logOutMessage}/>}
        </div> 
        <Routes>
        <Route index element={<Login />} />
        <Route path="first-time-login" element={<FirstTimeLogin />} />
        <Route path="otp-recovery" element={<OTPRecovery />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="recover-password" element={<RecoverPassword />} />
        </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
