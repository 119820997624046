import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ApplicationService from "../services/application-service/application-data-service";
import '../App.css'

const statusEnum = [
    { value: "good", label: "good" },
    { value: "warning", label: "warning" },
    { value: "bad", label: "bad" }
]


const UpdateApp = (loggedInUser) => {

    const params = useParams();
    const navigate = useNavigate();

    const [currentUser, setCurrentUser] = useState();
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const isApplication = useState({ id: "", appName: "", envName: "", description: "", endpoint: "",
      storageStatus: "", upTimeStatus: "",  memoryStatus: "", queryDate: "", notes: "" });

    const [activeApplication, setActiveApplication] = useState(isApplication);
    
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);

    
    
        const validateForm = () => {
        let isValid = true;
        const newErrors = {};
    
        if (!activeApplication.appName) {
            newErrors.appName = "App Name is required";
            isValid = false;
        }

        if (!activeApplication.envName) {
            newErrors.storageEndpoint = "Env is required";
            isValid = false;
        }

        if (!activeApplication.description) {
          newErrors.description = "Description is required";
          isValid = false;
        }

        if (!activeApplication.endpoint) {
          newErrors.endpoint = "Endpoint is required";
          isValid = false;
        }

        if (!activeApplication.storageStatus) {
            newErrors.storageStatus = "Storage Status is required";
            isValid = false;
        }

        if (!activeApplication.upTimeStatus) {
            newErrors.upTimeStatus = "Uptime Status is required";
            isValid = false;
        }

        if (!activeApplication.memoryStatus) {
            newErrors.memoryStatus = "Memory Status is required";
            isValid = false;
          }
    
         if (!activeApplication.notes || activeApplication.notes) {
            isValid = true;
        }
    
        setErrors(newErrors);
        return isValid;
      };  

      

    useEffect(() => {
        window.scrollTo(0, 0);

        const user = loggedInUser.loggedInUser
        if (user) {
            setCurrentUser(user);
            if (!(user.role === "Admin" || user.role === "Super Admin" || user.role === "Developer")) {
                setMessage("Unauthorized to Update Applications!");
                setTimeout(() => {
                    setMessage("");
                    navigate("/");
                    window.location.reload();}, 3300)
                    return;
            }; 
        };

        ApplicationService.viewApplicationById(params.id).then(
            (response) => { 
              const data = response.data;
              setActiveApplication(data);
            },
            (error) => {
              const message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
          
              setMessage(message)
            }
          ); 

        
    }, [params, setCurrentUser, setActiveApplication, navigate, loggedInUser]);


    const handleInputChange = (e) => {
      const { name, value } = e.target;
  
      setActiveApplication((item) => ({
          ...item,
          [name]: value
      }));
      };

    const handleUpdateApp = async (e) => {
        e.preventDefault();

        if (validateForm()) { 
            ApplicationService.updateApplicationById(activeApplication._id, activeApplication.appName, activeApplication.envName, 
              activeApplication.description, activeApplication.endpoint, activeApplication.storageStatus, activeApplication.upTimeStatus, 
              activeApplication.memoryStatus, activeApplication.notes).then(
                () => {
                    setSuccess("Application Updated Successfully!");
                    setLoading(true);
                    console.log("Active Application:", activeApplication);
                    setSubmitted(true); 
                    setTimeout(() => {
                        setLoading(false);
                        setSuccess("");
                        navigate("/applications");
                        window.location.reload();}, 2300)
                },
                (error) => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
    
                    setMessage(message);
                }
            );
        
          } else {
            // Form is not valid, display error messages
          } }

          

    return (
<div className="App">
    {currentUser && activeApplication && (currentUser.role === "Admin" || currentUser.role === "Super Admin" || currentUser.role === "Developer")
     && (<div>

{/* <section className="container-fluid">
        <section className="row">
          <aside className="sidebar sidebar-nav border border-right col-md-3 col-lg-2">
            <div className="offcanvas-md offcanvas-end bg-body-tertiary" tabindex="-1" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="sidebarMenuLabel">Company name</h5>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu" aria-label="Close"></button>
              </div>
              <div className="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto">
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/">
                    <i className="bi-house-fill"></i>
                    Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="/applications">
                      <i className="bi bi-grid"></i><span>Applications</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    {data && data.length >= 1 ? (
                      data.map((item, key) =>
                    <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                      <Link to={`../view-application/${item.id}`}>
                      <li className="nav-item" key={key} onClick={ () => setViewActiveApplication(item, item.id) }>
                        <i className="bi bi-circle"></i><span>{item.appName}</span>
                      </li>
                      </Link>
                    </ul>)
                  ) : (
                    <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                      <li className="nav-item">
                        <span>No Apps Found!</span>
                      </li>
                    </ul>
                  )}
                    </li>
                    <li className="nav-item">
                    <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/users">
                    <i className="bi-people"></i>
                    Users
                    </a>
                  </li>
                  </ul>
              </div>
            </div>
          </aside>
          </section>
          </section> */}

     <div className="page-container-static" id="page_container_toggle">
      <div className="config-list-group-table">
        <div className="register-container">
        {message && (<div className="error">{message}</div>)}
            <div className="register-header">
                <p>Update Application Below - {activeApplication.appName}</p>
            </div>
            <div className="register-body">
                <div className="col-md-12">
        {loading && submitted ? (<div className="info">{success}</div> 
        ) : (
                    <form className="insertForm"
                        onSubmit={handleUpdateApp} >
                        <div className="register-column">
                            <label htmlFor="appName">App Name*</label>
                            <input
                                type="text"
                                name="appName"
                                className="form-control"
                                value={activeApplication.appName}
                                onChange={handleInputChange}
                                autoComplete="off"
                                required
                            />
                            {errors.appName && <div className="form-error">{errors.appName}</div>}
                        </div>
                        <div className="register-column">
                            <label htmlFor="envName">Environment Name*</label>
                            <input
                                type="text"
                                name="envName"
                                className="form-control"
                                value={activeApplication.envName}
                                onChange={handleInputChange}
                                autoComplete="off"
                                required
                            />
                            {errors.envName && <div className="form-error">{errors.envName}</div>}
                        </div>
                        <div className="register-column">
                            <label htmlFor="description">Description*</label>
                            <input
                                type="text"
                                name="description"
                                className="form-control"
                                value={activeApplication.description}
                                onChange={handleInputChange}
                                autoComplete="off"
                                required
                            />
                            {errors.description && <div className="form-error">{errors.description}</div>}
                        </div>
                        <div className="register-column">
                            <label htmlFor="endpoint">Endpoint*</label>
                            <input
                                type="text"
                                name="endpoint"
                                className="form-control"
                                value={activeApplication.endpoint}
                                onChange={handleInputChange}
                                autoComplete="off"
                                required
                            />
                            {errors.endpoint && <div className="form-error">{errors.endpoint}</div>}
                        </div>
                        <div className="register-column">
                            <label htmlFor="storageStatus">Storage Status*</label>
                            <select
                                type="text"
                                name="storageStatus"
                                className="form-control"
                                value={activeApplication.storageStatus}
                                onChange={handleInputChange}
                                autoComplete="off"
                                issearchable="true"
                                required
                            >
                                {statusEnum.map((item) => 
                                <option key={item.label} value={item.value}>{item.label}</option>)}
                            </select>
                            {errors.storageStatus && <div className="form-error">{errors.storageStatus}</div>}
                        </div>
                        <div className="register-column">
                            <label htmlFor="upTimeStatus">Uptime Status*</label>
                            <select
                                type="text"
                                name="upTimeStatus"
                                className="form-control"
                                value={activeApplication.upTimeStatus}
                                onChange={handleInputChange}
                                autoComplete="off"
                                issearchable="true"
                                required
                            >
                                {statusEnum.map((item) => 
                                <option key={item.label} value={item.value}>{item.label}</option>)}
                            </select>
                            {errors.upTimeStatus && <div className="form-error">{errors.upTimeStatus}</div>}
                        </div>
                        <div className="register-column">
                            <label htmlFor="memoryStatus">Memory Status*</label>
                            <select
                                type="text"
                                name="memoryStatus"
                                className="form-control"
                                value={activeApplication.memoryStatus}
                                onChange={handleInputChange}
                                autoComplete="off"
                                issearchable="true"
                                required
                            >
                                {statusEnum.map((item) => 
                                <option key={item.label} value={item.value}>{item.label}</option>)}
                            </select>
                            {errors.memoryStatus && <div className="form-error">{errors.memoryStatus}</div>}
                        </div>
                        <div className="register-column">
                            <label htmlFor="notes">Notes</label>
                            <input
                                type="text"
                                name="notes"
                                className="form-control"
                                value={activeApplication.notes}
                                onChange={handleInputChange}
                                placeholder="Optional"
                                autoComplete="off"
                            />
                            {errors.notes && <div className="form-error">{errors.notes}</div>}
                        </div>
                        <div className="register-button-area">
                            <button className="register-button" type="submit" /* disabled={!isFormValid} */>
                                Update App
                            </button>
                        </div>
                    </form>
                )}
                </div>
            </div>

        </div>
    </div>
</div>
</div>
)}


  {currentUser && !(currentUser.role === "Admin" || currentUser.role === "Super Admin" || currentUser.role === "Developer") &&
  <div className="page-container-static">
  <div className="login-prompt-container">
  <div className="error">
      {message} <br /> <br /> 
      Redirecting to Homepage....
  </div>
  </div>
  </div>}


</div>
    );
}

export default UpdateApp;