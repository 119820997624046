import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "react-avatar";
import ApplicationService from "../services/application-service/application-data-service";
import ViewApplication from "./View-Application";
import { Link } from "react-router-dom";
import '../App.css'
import "bootstrap/dist/js/bootstrap.bundle.js"


const Register = (loggedInUser) => {

    const navigate = useNavigate();

    const [currentUser, setCurrentUser] = useState();
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [formData, setFormData] = useState({
        appName: "", storageEndpoint: "", upTimeEndpoint: "", memoryEndpoint: "", notes: ""
    });
    const isApplication = useState({ id: "", appName: "", envName: "", description: "", endpoint: "",
        storageStatus: "", upTimeStatus: "",  memoryStatus: "", queryDate: "", notes: "" });
    const [data, setData] = useState([]);
    const [activeApplication, setActiveApplication] = useState(isApplication);
    const [isLoadApplication, setLoadApplication] = useState(false);

    
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
    
        setFormData({
          ...formData,
          [name]: value
        });
      };
    
        const validateForm = () => {
        let isValid = true;
        const newErrors = {};
    
        if (!formData.appName) {
            newErrors.appName = "App Name is required";
            isValid = false;
        }

        if (!formData.envName) {
            newErrors.envName = "Environment Name is required";
            isValid = false;
        }

        if (!formData.description) {
            newErrors.description = "Description is required";
            isValid = false;
        }

        if (!formData.endpoint) {
          newErrors.endpoint = "Endpoint is required";
          isValid = false;
        }
  
         if (!formData.notes || formData.notes) {
            isValid = true;
        }
    
        setErrors(newErrors);
        return isValid;
      }; 

      

    useEffect(() => {
        window.scrollTo(0, 0);

        const user = loggedInUser.loggedInUser
        if (user) {
            setCurrentUser(user);
            if (!(user.role === "Admin" || user.role ==="Super Admin")) {
                setMessage("Only Admins can add new Applications!");
                setTimeout(() => {
                    setMessage("");
                    navigate("/home");
                    window.location.reload();}, 3300)
                    return;
            };
        };

        ApplicationService.allApplications().then(
            (response) => {
              const data = response.data.reverse();
              const postData = data.map(item => ({
                id: item._id, appName: item.appName, envName: item.envName, description: item.description,
                endpoint: item.endpoint, storageStatus: item.storageStatus, upTimeStatus: item.upTimeStatus, 
                memoryStatus: item.memoryStatus, createdBy: item.createdBy, notes: item.notes, queryDate: item.queryDate
              }))
              setData(postData);
            },
            (error) => {
              const message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
      
              setMessage(message)
            }
          );
      
      
          setActiveApplication(activeApplication);

        
    }, [setCurrentUser, activeApplication, navigate, loggedInUser]);


    const setViewActiveApplication = (application) => {
        setActiveApplication(application);
        setLoadApplication(true);
      };

    const handleRegisterApp = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            ApplicationService.createApplication(formData.appName, formData.envName, formData.description, 
                formData.endpoint, formData.notes).then(
                () => {
                    setSuccess("Application Created Successfully!");
                    setLoading(true);
                    console.log("Form data:", formData);
                    setSubmitted(true); 
                    setTimeout(() => {
                        setLoading(false);
                        setSuccess("");
                        navigate("/applications");
                        window.location.reload();}, 2300)
                },
                (error) => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
    
                    setMessage(message);
                }
            );
        
          } else {
            // Form is not valid, display error messages
          }}

          

    return (
<div className="App">
    {currentUser && (currentUser.role === "Admin" || currentUser.role === "Super Admin") && (<div>

        <section className="container-fluid">
        <section className="row">
          <aside className="sidebar sidebar-nav border border-right col-md-3 col-lg-2">
            <div className="offcanvas-md offcanvas-end bg-body-tertiary" tabIndex="-1" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="sidebarMenuLabel">Company name</h5>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu" aria-label="Close"></button>
              </div>
              <div className="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto">
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/">
                    <i className="bi-house-fill"></i>
                    Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="/applications">
                      <i className="bi bi-grid"></i><span>Applications</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    {data && data.length >= 1 ? (
                      data.map((item, key) =>
                    <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                      <Link to={`../view-application/${item.id}`}>
                      <li className="nav-item" key={key} onClick={ () => setViewActiveApplication(item, item.id) }>
                        {/* <i className="bi bi-circle"></i> */}
                        {(item.appName === "Ibima" || item.appName === "Bima" || item.appName === "Nanatec" || 
                            item.appName === "Nexus" || item.appName === "Unitrust") ? 
                            
                          <img src={require(`../images/${item.appName}-logo.png`)} 
                          alt="" style={{ width: "20px", height: "20px", borderRadius: "50%" }}/>
                          
                          : 
                          <Avatar name={item.appName + " " + item.appName} size="20" round={true} />
                        }
                        <span> {item.appName}</span>
                      </li>
                      </Link>
                    </ul>)
                  ) : (
                    <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                      <li className="nav-item">
                        <span>No Apps Found!</span>
                      </li>
                    </ul>
                  )}
                    </li>
                    <li className="nav-item">
                    <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/users">
                    <i className="bi-people"></i>
                    Users
                    </a>
                  </li>
                  </ul>
              </div>
            </div>
          </aside>

          <main className="dashboard col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="pagetitle">
                {/* <h1>Dashboard</h1> */}
                <nav className="breadcrumbs">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a className="breadcrumb-menu-a" href="/">Home</a></li>
                    <li className="breadcrumb-item"><a className="breadcrumb-menu-a" href="/users">Users</a></li>
                    <li className="breadcrumb-item"><a className="breadcrumb-menu-a" href="/applications">Applications</a></li>
                    <li className="breadcrumb-item"><span style={{ backgroundColor: "#555", color: "white", borderRadius: "5px", padding: "5px" }}>Create New App</span></li>
                  </ol>
                </nav>
              </div>

          {/* </section>
          </section> */}

<section className="section dashboard">
          <div className="row">
        <div className="col-lg">
      <div className="row">
        <div className="register-container">
        {message && (<div className="error">{message}</div>)}
            <div className="register-header">
                <h5>Create Application Below</h5>
            </div>
            <div className="register-body">
                <div className="col-md-12">
        {loading && submitted ? (<div className="info">{success}</div> 
        ) : (
                    <form className="insertForm"
                        onSubmit={handleRegisterApp} >
                        <div className="register-column">
                            <label htmlFor="appName">App Name*</label>
                            <input
                                type="text"
                                name="appName"
                                className="form-control"
                                value={formData.appName}
                                onChange={handleInputChange}
                                autoComplete="off"
                                required
                            />
                            {errors.appName && <div className="form-error">{errors.appName}</div>}
                        </div>
                        <div className="register-column">
                            <label htmlFor="envName">Environment Name*</label>
                            <input
                                type="text"
                                name="envName"
                                className="form-control"
                                value={formData.envName}
                                onChange={handleInputChange}
                                autoComplete="off"
                                required
                            />
                            {errors.envName && <div className="form-error">{errors.envName}</div>}
                        </div>
                        <div className="register-column">
                            <label htmlFor="description">Description*</label>
                            <input
                                type="text"
                                name="description"
                                className="form-control"
                                value={formData.description}
                                onChange={handleInputChange}
                                autoComplete="off"
                                required
                            />
                            {errors.description && <div className="form-error">{errors.description}</div>}
                        </div>
                        <div className="register-column">
                            <label htmlFor="endpoint">Endpoint*</label>
                            <input
                                type="text"
                                name="endpoint"
                                className="form-control"
                                value={formData.endpoint}
                                onChange={handleInputChange}
                                autoComplete="off"
                                required
                            />
                            {errors.endpoint && <div className="form-error">{errors.endpoint}</div>}
                        </div>
                        <div className="register-column">
                            <label htmlFor="notes">Notes</label>
                            <input
                                type="text"
                                name="notes"
                                className="form-control"
                                value={formData.notes}
                                onChange={handleInputChange}
                                placeholder="Optional"
                                autoComplete="off"
                            />
                            {errors.notes && <div className="form-error">{errors.notes}</div>}
                        </div>
                        <div className="register-button-area">
                            <button className="register-button" type="submit" /* disabled={!isFormValid} */>
                                Create App
                            </button>
                        </div>
                    </form>
                )}
                </div>
            </div>

        </div>
    </div>
</div>

</div>
</section>
</main>
</section>
</section>

<div>
      {isLoadApplication &&
          <ViewApplication list={activeApplication} />
        }
        </div>
</div>
)}


  {currentUser && !(currentUser.role === "Admin" || currentUser.role === "Super Admin") &&
  <div className="page-container-static">
  <div className="login-prompt-container">
  <div className="error">
      {message} <br /> <br /> 
      Redirecting to Homepage....
  </div>
  </div>
  </div>}


</div>
    );
}

export default Register;